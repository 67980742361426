import ApiAnggota from "../api/data-anggota.js";
import ApiRegister from "../api/data-register.js";
import API_ENDPOINT from "../config/globals/endpoint.js";
import NotificationModal from "../utils/initial_notification.js";
import CONFIG from "../config/globals/config.js";
import DataFile from "../api/data-file.js";
import API_MENU_ANGGOTA_ENDPOINT from "../config/globals/menu_anggota_endpoint.js";
import ApiNotication from "../api/data-notification.js";
import UrlParser from "../routes/url-parser.js";
import Autentication from "../utils/autentication.js";


const OverviewInitiator = {


	async init() {
		await this.initViewDashboard();
		await this._sendPengajuanKTAN();
		await this._getVerifikasiKTAN();
		await this._getPendidikan();
		await this._searchPendidikan();
		await this._getPekerjaan();
		await this._getOrganisasi();
		await this._getSTRTTK();
		await this._getSIPTTK();
		await this._getSERKOM();
		await this.initProvinceValue();
		await this.initProvinceValueDomicille();
		await this._getDataPrivate();
		await this._updateDataPrivate();
		await this._updateDataDomicille();
		await this._createPendidikan();
		await this._createPekerjaan();
		await this._createOrganisasi();
		await this._createSTRTTK();
		await this._createSIPTTK();
		await this._createSERKOM();
		await this._clearFormPendidikan();
		await this._clearFormPekerjaan();
		await this._clearFormOrganisasi();
		await this._clearFormSTRTTK();
		await this._getPekerjaanSIP();
		await this._refreshPekerjaan();
		await this.initCommentUser();
		await this.initEventCommentar();
		await this._downloadKTAN();
		await this._readNotification();
	},

	
	
	async _readNotification() {
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},


	async initViewDashboard() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$('.chat-body').scrollTop($('.chat-body')[0].scrollHeight);
		$("#btnUpdatePendidikan").hide();
		$("#btnUpdatePekerjaan").hide();
		$("#btnUpdateOrganisasi").hide();
		$("#btnUpdateSTRTTK").hide();
		$("#btnUpdateSIPTTK").hide();
		$("#btnUpdateSERKOM").hide();
		$("#alert-iuran-warning").hide();
		$("#alert-serkom-warning").hide();
		$("#alert-sipttk-warning").hide();
		$("#alert-strttk-warning").hide();
		$("#alert-serkom-danger").hide();
		$("#alert-sipttk-danger").hide();
		$("#alert-strttk-danger").hide();
		$("#alert-ktan-danger").hide();
		$("#alert-ktan-warning").hide();
		$("#btnSendRenewKTAN").hide();
		
		document.getElementById('oldForm').classList.remove('d-none');
		const eventChangeJenisSTRTTK = async () => {
			let jenisSTRTTK = document.getElementById('selectJenisSTRTTK').value;
	
			if (jenisSTRTTK == 'old') {
				document.getElementById('oldForm').classList.remove('d-none');
				document.getElementById('newForm').classList.add('d-none');
			}else {
				document.getElementById('oldForm').classList.add('d-none');
				document.getElementById('newForm').classList.remove('d-none');
			}
		}

		document.getElementById('selectJenisSTRTTK').addEventListener('change', eventChangeJenisSTRTTK)

	},


	async _showLoading() {

		var loadingElement = document.createElement("div");
		loadingElement.setAttribute("id", "loading");

		var spinnerElement = document.createElement("div");
		spinnerElement.setAttribute("class", "spinner");

		for (var i = 0; i < 3; i++) {
			var barElement = document.createElement("div");
			barElement.setAttribute("class", "bar");
			spinnerElement.appendChild(barElement);
		}

		loadingElement.appendChild(spinnerElement);
		document.body.appendChild(loadingElement);
	},

	async _hideLoading() {
		var loadingElement = document.getElementById("loading");
		if (loadingElement) {
			loadingElement.parentNode.removeChild(loadingElement);
		}
	},
	
	

	async _sendPengajuanKTAN() {

		const eventSendPengajuanKTAN = async (e) => {
			swal.fire({
				title: 'Disclaimer !',
				html: "Saya Bersaksi Bahwa Data yang saya Input atau Upload adalah Data yang sebenar-benarnya. Dan saya menyetujui serta bertanggungjawab penuh atas data tersebut apabila ada kekeliruan dikemudian hari. ",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ajukan!'
			}).then(async (result) => {
				if (result.value) {
					e.preventDefault();
					let type = 'new';
					document.getElementById("btnKirimPengajuan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const sendPengajuanKTAN = await ApiAnggota.sendPengajuanKTAN({
						type : type
					});

					// if (sendPengajuanKTAN.status_code != 200) {
					// 	NotificationModal.show(`${sendPengajuanKTAN.message}`, 'error');
					// 	document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-edit"></i> Kirim Pengajuan KTAN`;
					// } else {
					// 	NotificationModal.show(`${sendPengajuanKTAN.message}`, 'success');
					// 	document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-edit"></i> Kirim Pengajuan KTAN`;
					// }
					if (sendPengajuanKTAN.status_code == '200' || sendPengajuanKTAN.status_code == '201') {
						swal.fire({
							title: 'Success',
							text: sendPengajuanKTAN.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});


						document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-paper-plane"></i> Kirim Pengajuan KTAN`

					} else {
						swal.fire({
							title: 'Error',
							text: sendPengajuanKTAN.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});

		}
		
		const eventRenewKTAN = async (e) => {
			swal.fire({
				title: 'Disclaimer !',
				html: "Saya Bersaksi Bahwa Data yang saya Input atau Upload adalah Data yang sebenar-benarnya. Dan saya menyetujui serta bertanggungjawab penuh atas data tersebut apabila ada kekeliruan dikemudian hari. ",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Ajukan!'
			}).then(async (result) => {
				if (result.value) {
					e.preventDefault();
					let type = 'renew';
					document.getElementById("btnSendRenewKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
					const sendPengajuanKTAN = await ApiAnggota.sendPengajuanKTAN({
						type : type
					});

					// if (sendPengajuanKTAN.status_code != 200) {
					// 	NotificationModal.show(`${sendPengajuanKTAN.message}`, 'error');
					// 	document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-edit"></i> Kirim Pengajuan KTAN`;
					// } else {
					// 	NotificationModal.show(`${sendPengajuanKTAN.message}`, 'success');
					// 	document.getElementById("btnKirimPengajuan").innerHTML = `<i class="fas fa-edit"></i> Kirim Pengajuan KTAN`;
					// }
					if (sendPengajuanKTAN.status_code == '200' || sendPengajuanKTAN.status_code == '201') {
						swal.fire({
							title: 'Success',
							text: sendPengajuanKTAN.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});


						document.getElementById("btnSendRenewKTAN").innerHTML = `<i class="fas fa-paper-plane"></i> Perpanjang KTAN`

					} else {
						swal.fire({
							title: 'Error',
							text: sendPengajuanKTAN.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});

		}
		document.getElementById('btnKirimPengajuan').addEventListener('click', eventSendPengajuanKTAN);
		document.getElementById('btnSendRenewKTAN').addEventListener('click', eventRenewKTAN);
	},

	async _searchPendidikan() {
		$("#pendidikanResult").hide();

		let timeoutId;
		const searchPendidikan = async (e) => {
			e.preventDefault();
			clearTimeout(timeoutId)
			let key = document.getElementById('textInstitusiPendidikan').value;
			if (key != "") {
				$("#pendidikanResult").html("<button class='list-group-item list-group-item-action disabled'>Loading...</button>");
				$("#pendidikanResult").show();
				timeoutId = setTimeout(async () => {

					const getPendidikanFromPublic = await ApiAnggota.searchPendidikan({
						key: key
					});
					let dataPendidikan = getPendidikanFromPublic.data;
					$("#pendidikanResult").html('');
					dataPendidikan.forEach(data => {
						const institusiPendidikan = data.text;
						const regex = /Nama PT: (.+?),/i;
						const matches = institusiPendidikan.match(regex);

						const namaPT = matches[1]

						$("#pendidikanResult").append(`<button type="button" class="list-group-item list-group-item-action">${namaPT}</button>`)

					});

				}, 2000);
			} else {
				$("#pendidikanResult").hide();
				$("#pendidikanResult").html('');
			}
		}

		$("#pendidikanResult").on('click', '.list-group-item', async function () {
			let text = $(this).html();
			console.log(text)
			$('#textInstitusiPendidikan').val(text);
			$("#pendidikanResult").hide();
		})

		document.getElementById('textInstitusiPendidikan').addEventListener('keyup', searchPendidikan);
		document.getElementById('textInstitusiPendidikan').addEventListener('focusout', () => {
			setTimeout(() => {
				$("#pendidikanResult").hide();
			}, 500);

		})
	},


	async _getPendidikan() {
		$("#profile-pendidikan-tab").one("click", async function () {
			let table = await $("#tableDataPendidikan").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_PENDIDIKAN}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'jenjang' },
					{ data: 'nama_institusi_pendidikan' },
					{ data: 'tahun_lulus' },
					{ data: 'nomor_ijazah' },
					{ data: 'tanggal_ijazah' },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 6,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data pendidikan'><i class='far fa-edit'></i></button>
						 <button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data pendidikan'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 5,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		});
	},

	async _getPekerjaan() {

		$("#pekerjaan-tab").one("click", async function () {
			let table = await $("#tableDataPekerjaan").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_PEKERJAAN}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'jenis_tempat_kerja' },
					{ data: 'nama_tempat_kerja' },
					{ data: 'alamat_tempat_kerja' },
					{ data: 'telp_fax' },
					{ data: 'phone_number' },
					{ data: 'email' },
					{ data: 'jabatan' },
					{ data: 'dari_tahun' },
					{ data: 'sampai_tahun' },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 10,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data pendidikan'><i class='far fa-edit'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data pendidikan'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 9,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		})


	},

	async _getOrganisasi() {

		$("#karir-organisasi-tab").one("click", async function () {
			let table = await $("#tableDataRiwayatOrganisasi").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_ORGANISASI}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'nama_organisasi' },
					{ data: 'alamat_organisasi' },
					{ data: 'jabatan' },
					{ data: 'dari_tahun' },
					{ data: 'sampai_tahun' },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 6,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data Organisasi'><i class='far fa-edit'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data Organisasi'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 5,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		});


	},

	async _getSIPTTK() {
		$("#sipttk-tab").one("click", async function () {

			let table = await $("#tableSIPTTK").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_SIPTTK}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'nomor_strttk' },
					{ data: 'nomor_sipttk' },
					{ data: 'tanggal' },
					{ data: 'berlaku_sampai' },
					{ data: 'jenis_sipttk' },
					{ data: 'tempat_kerja.nama_tempat_kerja' },
					{ data: 'tempat_kerja.alamat_tempat_kerja' },
					{ data: 'nama_apoteker_supervisi' },
					{ data: 'nomor_stra_apoteker' },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 10,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data Organisasi'><i class='far fa-edit'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data Organisasi'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 9,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		})


	},

	async _getSTRTTK() {
		$("#strttk-tab").one("click", async function () {
			let table = await $("#tableSTRTTK").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_STRTTK}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'nomor_strttk' },
					{ data: 'tanggal' },
					{ data: 'berlaku_sampai', render : function (data) {
						if (data == 'lifetime') {
							return 'Seumur Hidup'
						}else return data
					} },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 4,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data'><i class='far fa-edit'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data Organisasi'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 3,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		})


	},

	async _getVerifikasiKTAN() {
		let table = await $("#tableHistoryVerifikasiKTAN").dataTable({
			"processing": true,
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1000,
			"ajax": {
				"url": `${API_ENDPOINT.GET_TRACKING_KTAN}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{
					"data": null,
					"class": "align-top",
					"searchable": false,
					"render": function (data, type, row, meta) {
						return meta.row + meta.settings._iDisplayStart + 1;
					}
				},
				{ data: 'created_at' },
				{ data: 'actor.name' },
				{ data: 'actor_type' },
				{ data: 'description' },
			],

			"order": [[0, 'desc']],
		});


	},

	async _getSERKOM() {

		$("#serkom-tab").on("click", async function () {
			let table = await $("#tableSERKOM").dataTable({
				"processing": true,
				'language': {
					'loadingRecords': '&nbsp;',
					'processing': '<div class="spinner"></div>'
				},
				"serverSide": true,
				"searchDelay": 1000,
				"ajax": {
					"url": `${API_ENDPOINT.CREATE_SERKOM}`,
					"type": "GET",
					"beforeSend": function (xhr) {
						xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
					}
				},
				"columns": [
					{ data: 'nomor_serkom' },
					{ data: 'tanggal' },
					{ data: 'berlaku_sampai' },
					{
						data: 'dokumen', render: function (data) {
							if (data == null || data == "") {
								return '-'
							} else {
								return '<img loading="lazy" id="show" src="' + data + '" onerror="this.onerror=null; this.src=\'./src/public/img/image-corrupt-min.png\';" data-original="' + data + '" width="40px">'
							}
						}
					},
				],
				"columnDefs": [{
					"targets": 4,
					"data": null,
					"defaultContent": `<div style="display:flex"><button style="margin:1px" class='btn btn-info btn-circle' id='edit' title='Edit data'><i class='far fa-edit'></i></button>
						<button style="margin:1px" class='btn btn-danger btn-circle' id='delete' title='Hapus data'><i class='fas fa-trash'></i></button></div>`
				},
				{
					"targets": 3,
					"visible": true,
					"searchable": true,
					"className": "text-center",
				}
				]
			});
		});


	},

	async _dropzoneUploader(user) {
		let DzoneKTP = new Dropzone("#form-upload-ktp", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				if (user.data.dokumen.ktp_photo != "") {
					var mockFile = { name: `KTP-${user.data.name}.png`, size: 12345, type: '.jpeg,.jpg,.png,.gif', img_alt: `${user.data.name}`, isFromServer: true };
					this.addFile.call(this, mockFile);
					this.options.thumbnail.call(this, mockFile, user.data.dokumen.ktp_photo);
				}

			}
		});

		let DzoneFoto = new Dropzone("#form-upload-foto", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				if (user.data.avatar != "") {
					var mockFile = { name: `Avatar-${user.data.name}.png`, size: 12345, type: '.jpeg,.jpg,.png,.gif', isFromServer: true };
					this.addFile.call(this, mockFile);
					this.options.thumbnail.call(this, mockFile, user.data.avatar);
				}
			}
		});

		let DzonePembayaran = new Dropzone("#form-upload-pembayaran", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				if (user.data.dokumen.bukti_bayar_ktan != "") {
					var mockFile = { name: `BuktiBayar-${user.data.name}.png`, size: 12345, type: '.jpeg,.jpg,.png,.gif', isFromServer: true };
					this.addFile.call(this, mockFile);
					this.options.thumbnail.call(this, mockFile, user.data.dokumen.bukti_bayar_ktan);
				}

			}
		});

		let DzoneKTAN = new Dropzone("#form-upload-ktan-lama", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				if (user.data.dokumen.old_ktan != "") {
					var mockFile = { name: `KTAN-${user.data.name}.png`, size: 12345, type: '.jpeg,.jpg,.png,.gif', isFromServer: true };
					this.addFile.call(this, mockFile);
					this.options.thumbnail.call(this, mockFile, user.data.dokumen.old_ktan);
				}
			}
		});


		const eventUpload = async (y) => {
			if (DzoneFoto.files.length == 0 || DzoneKTP.files.length == 0 || DzonePembayaran.files.length == 0) {
				NotificationModal.show(`File KTP , File PAS Foto , File Bukti Bayar Harus diisi semua`, 'warning');
				return
			} else {
				document.getElementById("btnUpdateDataFile").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			}

			try {
				if (DzoneKTAN.files.length == 0) {
					
				} else {
					if (DzoneKTAN.files[0].isFromServer === true &&
						DzoneFoto.files[0].isFromServer === true &&
						DzoneKTP.files[0].isFromServer === true &&
						DzonePembayaran.files[0].isFromServer === true
					) {
						NotificationModal.show(`Silahkan update foto`, 'warning');
						document.getElementById("btnUpdateDataFile").innerHTML = `<i class="fas fa-edit"></i>  Update Files`;
						return
					}
				}
			} catch (error) {
				console.log(error)
				document.getElementById("btnUpdateDataFile").innerHTML = `<i class="fas fa-edit"></i>  Update Files`;
				return
			}
			let fileKTAN = null
			try {
				fileKTAN = DzoneKTAN.files[0].isFromServer ? null : await this.dataUrlToFile(DzoneKTAN.files[0].dataURL, DzoneKTAN.files[0].name)
			} catch (error) {
				console.log(error);
			}

			let response = await DataFile.uploadImageProfile(
				{
					imageKtp: DzoneKTP.files[0].isFromServer ? null : await this.dataUrlToFile(DzoneKTP.files[0].dataURL, DzoneKTP.files[0].name),
					imageKtan: fileKTAN,
					imagePhotoAvatar: DzoneFoto.files[0].isFromServer ? null : await this.dataUrlToFile(DzoneFoto.files[0].dataURL, DzoneFoto.files[0].name),
					imagePembayaran: DzonePembayaran.files[0].isFromServer ? null : await this.dataUrlToFile(DzonePembayaran.files[0].dataURL, DzonePembayaran.files[0].name)
				}
			)

			if (response.status_code == 200) {
				document.getElementById("btnUpdateDataFile").innerHTML = `<i class="fas fa-edit"></i> Update Files`;
				swal.fire({
					title: 'Success',
					html: ` ${response.message} !<br> Reload halaman untuk menyesuaikan file ? `,
					icon: 'success',
					showCancelButton: false,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: '<i class="fas fa-check"></i> Ya, Reload Halaman'
				}).then(async (result) => {
					if (result.value) {
						window.location.reload();
					};
				});
			} else {
				NotificationModal.show(`${response.message}`, 'error');
				document.getElementById("btnUpdateDataFile").innerHTML = `<i class="fas fa-edit"></i>  Update Files`;
			}
			y.preventDefault()
		}
		document.getElementById("btnUpdateDataFile").addEventListener("click", eventUpload)
	},

	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	async _getDataPrivate() {
		const dataPrivate = await ApiAnggota.getPrivate();
		const dataAddressPrivate = dataPrivate.data;
		if (dataPrivate.status_code == 200) {
			$("#infoRek").html(dataAddressPrivate.information.regency.rekening);
			$("#email_pp").html(dataAddressPrivate.information.pusat.email)
			$("#telp_pp").html(dataAddressPrivate.information.pusat.telp)
			$("#rek_pp").html(dataAddressPrivate.information.pusat.rekening)
			$("#email_pd").html(dataAddressPrivate.information.province.email)
			$("#telp_pd").html(dataAddressPrivate.information.province.telp)
			$("#rek_pd").html(dataAddressPrivate.information.province.rekening)
			$("#email_pc").html(dataAddressPrivate.information.regency.email)
			$("#telp_pc").html(dataAddressPrivate.information.regency.telp)
			$("#rek_pc").html(dataAddressPrivate.information.regency.rekening)
			Autentication.saveAvatar(dataAddressPrivate.avatar);
			dataAddressPrivate.addresses.forEach(async (data) => {
				if (data.type == "ktp") {
					if (data.regency_id == null) {
						var default_kota = ''
					} else {
						var default_kota = data.regency_id
					}
					if (data.sub_regency_id == null) {
						var default_kecamatan = ''
					} else {
						var default_kecamatan = data.sub_regency_id
					}

					if (data.village_id == null) {
						var default_kelurahan = ''
					} else {
						var default_kelurahan = data.village_id
					}


					$("#textAlamat").val(data.address);
					$("#selectProvinsi").val(data.province_id);
					const dataRegistry = await ApiRegister.getRegistryKemendagri(data.province_id)
					if (dataRegistry.status_code == 200 || dataRegistry.status_code == 201) {
						dataRegistry.data.forEach(data => {
							document.getElementById("selectKota").add(new Option(data.name, data.city_code))
						});
						$("#selectKota").val(default_kota);

						const dataSubRegency = await ApiRegister.getSubRegencyKemendagri(data.regency_id)

						if (dataSubRegency.status_code == 200 || dataSubRegency.status_code == 201) {
							dataSubRegency.data.forEach(data => {
								document.getElementById("selectKecamatan").add(new Option(data.name, data.district_code))
							});
							$("#selectKecamatan").val(default_kecamatan);

							const dataVillage = await ApiRegister.getVillageKemendagri(data.sub_regency_id)

							if (dataVillage.status_code == 200 || dataVillage.status_code == 201) {
								document.getElementById("selectKelurahan").add((new Option("Kelurahan / Desa", "")))
								dataVillage.data.forEach(data => {
									document.getElementById("selectKelurahan").add(new Option(data.name, data.village_code))
								});
								$("#selectKelurahan").val(default_kelurahan);
							}
						}
					}


				} else {

					if (data.regency_id == null) {
						var default_kota = `<option value=''>Kabupaten / Kota</option>`
					} else {
						var default_kota = `<option value='${data.regency_id}'>${data.regency_name}</option>`
					}
					if (data.sub_regency_id == null) {
						var default_kecamatan = `<option value=''>Kecamatan</option>`
					} else {
						var default_kecamatan = `<option value='${data.sub_regency_id}'>${data.sub_regency_name}</option>`
					}

					if (data.village_id == null) {
						var default_kelurahan = `<option value=''>Kelurahan / Desa</option>`
					} else {
						var default_kelurahan = `<option value='${data.village_id}'>${data.village_name}</option>`
					}
					$("#textAlamatDomisili").val(data.address);
					$("#selectProvinsiDomisili").val(data.province_id);
					$("#selectKotaDomisili").html(default_kota);
					$("#selectKecamatanDomisili").html(default_kecamatan);
					$("#selectKelurahanDomisili").html(default_kelurahan);

					// province to kota
					const dataRegistry = await ApiRegister.getRegistryKemendagri(data.province_id)

					var selectPC = document.getElementById("selectKotaDomisili");
					var lengthOptionPc = selectPC.options.length;
					for (let i = lengthOptionPc - 1; i >= 0; i--) {
						selectPC.options[i] = null;
					}
					document.getElementById("selectKotaDomisili").add((new Option("Kabupaten / Kota", "")))
					dataRegistry.data.forEach(delta => {
						document.getElementById("selectKotaDomisili").add(new Option(delta.name, delta.city_code))
					});

					document.getElementById("selectKotaDomisili").value = data.regency_id
					
					// kota to kecamatan
					let valueSelected = document.getElementById("selectKotaDomisili").value
					const dataSubRegency = await ApiRegister.getSubRegencyKemendagri(valueSelected)

					var selectKecamatan = document.getElementById("selectKecamatanDomisili");
					var lengthOptionKecamatan = selectKecamatan.options.length;
					for (let i = lengthOptionKecamatan - 1; i >= 0; i--) {
						selectKecamatan.options[i] = null;
					}
					
					document.getElementById("selectKecamatanDomisili").add((new Option("Kecamatan", "")))
					dataSubRegency.data.forEach(data => {
						document.getElementById("selectKecamatanDomisili").add(new Option(data.name, data.district_code))
					});
					document.getElementById("selectKecamatanDomisili").value = data.sub_regency_id
					
					// kecamatan to kelurahan
					let valueSelectedKecamatan = document.getElementById("selectKecamatanDomisili").value
					const dataVillage = await ApiRegister.getVillageKemendagri(valueSelectedKecamatan)
					var selectKelurahan = document.getElementById("selectKelurahanDomisili");
					var lengthOptionKelurahan = selectKelurahan.options.length;
					for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
						selectKelurahan.options[i] = null;
					}

					document.getElementById("selectKelurahanDomisili").add((new Option("Kelurahan / Desa", "")))
					dataVillage.data.forEach(data => {
						document.getElementById("selectKelurahanDomisili").add(new Option(data.name, data.village_code))
					});

					document.getElementById("selectKelurahanDomisili").value = data.village_id
				}



			});
		}

		if (dataAddressPrivate.notice_expired_soon.serkom) {
			$("#alert-serkom-warning").show();
			$("#alert-serkom-danger").hide();
		}

		if (dataAddressPrivate.notice_expired_soon.sipttk) {
			$("#alert-sipttk-danger").hide();
			$("#alert-sipttk-warning").show();
		}

		if (dataAddressPrivate.notice_expired_soon.strttk) {
			$("#alert-strttk-warning").show();
			$("#alert-strttk-danger").hide();
		}

		if (dataAddressPrivate.notice_expired_soon.ktan) {
			$("#alert-ktan-warning").show();
			$("#alert-ktan-danger").hide();
			$("#btnSendRenewKTAN").show()
		}

		if (dataAddressPrivate.notice_expired.serkom) {
			$("#alert-serkom-danger").show();
			$("#alert-serkom-warning").hide();
		}

		if (dataAddressPrivate.notice_expired.sipttk) {
			$("#alert-sipttk-danger").show();
			$("#alert-sipttk-warning").hide();
		}

		if (dataAddressPrivate.notice_expired.strttk) {
			$("#alert-strttk-danger").show();
			$("#alert-strttk-warning").hide();
		}

		if (dataAddressPrivate.notice_expired.ktan) {
			$("#alert-ktan-danger").show();
			$("#alert-ktan-warning").hide();
		}

		var ktan = dataAddressPrivate.ktan
		if (ktan === null || ktan === "") {
			$("#btnKirimPengajuan").removeClass("d-none");
		}
		else {
			$("#btnKirimPengajuan").addClass("d-none");
		}
		$("#textKTAN").val(dataAddressPrivate.ktan);
		$("#text_nian").html(dataAddressPrivate.ktan);
		$("#poto_ktan").attr("src", dataAddressPrivate.avatar);
		$("#barcode").attr("src", "https://barcode.tec-it.com/barcode.ashx?data=" + dataAddressPrivate.ktan + "&code=Code128&translate-esc=on");
		$("#textPC").val(dataAddressPrivate.member_pc);
		$("#textPD").val(dataAddressPrivate.member_pd);
		$("#email").val(dataAddressPrivate.email);
		$("#textNamaLengkap").val(dataAddressPrivate.name);
		$("#ktan_nama_anggota").html(dataAddressPrivate.name);
		$("#numberNIK").val(dataAddressPrivate.nik);
		$("#selectJenisKelamin").val(dataAddressPrivate.jenis_kelamin);
		$("#textTempatLahir").val(dataAddressPrivate.tempat_lahir);
		$("#dateTanggalLahir").val(dataAddressPrivate.tanggal_lahir);
		$("#numberNoHP").val(dataAddressPrivate.phone_number);
		await this._dropzoneUploader(dataPrivate)
	},

	async _updateDataPrivate() {
		const eventUpdatePrivate = async (e) => {
			e.preventDefault();

			document.getElementById("btnUpdateDataUmum").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const updatePrivate = await ApiAnggota.updateDataPrivate({
				name: document.getElementById('textNamaLengkap').value,
				address: document.getElementById('textAlamat').value,
				jenis_kelamin: document.getElementById('selectJenisKelamin').value,
				tempat_lahir: document.getElementById('textTempatLahir').value,
				tanggal_lahir: document.getElementById('dateTanggalLahir').value,
				phone_number: document.getElementById('numberNoHP').value,
				village_id: document.getElementById('selectKelurahan').value,
				sub_regency_id: document.getElementById('selectKecamatan').value,
				regency_id: document.getElementById('selectKota').value,
				province_id: document.getElementById('selectProvinsi').value,

			});

			if (updatePrivate.status_code != 200) {
				NotificationModal.show(`${updatePrivate.message}`, 'error');
				document.getElementById("btnUpdateDataUmum").innerHTML = `<i class="fas fa-edit"></i> Update Data`;
			} else {
				NotificationModal.show(`${updatePrivate.message}`, 'success');
				document.getElementById("btnUpdateDataUmum").innerHTML = `<i class="fas fa-edit"></i> Update Data`;
			}


			e.preventDefault();
		}
		document.getElementById('form-data-umum').addEventListener('submit', eventUpdatePrivate);
	},

	async _createPendidikan() {
		function createYearArray() {
			const currentYear = new Date().getFullYear()-1;
			const years = Array.from({ length: currentYear - 1969 }, (_, index) => 1970 + index);
			years.push("Sekarang");
			return years;
		}

		
		function createYearArrayStart() {
			const currentYear = new Date().getFullYear();
			const years = Array.from({ length: currentYear - 1969 }, (_, index) => 1970 + index);
			return years;
		}

		const SampaiTahun = createYearArray().reverse();
		const DariTahun = createYearArrayStart().reverse();

		var selectDariTahun = document.getElementById("dateDariTahun");
		var lengthOptionDariTahun = selectDariTahun.options.length;
		for (let i = lengthOptionDariTahun - 1; i >= 0; i--) {
			selectDariTahun.options[i] = null;
		}

		document.getElementById("dateDariTahun").add(new Option('Pilih Tahun',''))
		DariTahun.forEach(data => {
			document.getElementById("dateDariTahun").add(new Option(data))
		});

		
		var dateSampaiTahun = document.getElementById("dateSampaiTahun");
		var lengthOptionDariTahun = dateSampaiTahun.options.length;
		for (let i = lengthOptionDariTahun - 1; i >= 0; i--) {
			dateSampaiTahun.options[i] = null;
		}

		document.getElementById("dateSampaiTahun").add(new Option('Pilih Tahun',''))
		SampaiTahun.forEach(data => {
			document.getElementById("dateSampaiTahun").add(new Option(data))
		});

		

		let DzonePendidikan = new Dropzone("#form-upload-ijazah", {
			url: "/file/post",
			uploadMultiple: false,
			autoDiscover: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-ijazah .dz-preview:hidden').remove()
					$('#form-upload-ijazah .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-ijazah").classList.remove('dz-started');
				});
			}
		});


		const eventCreatePendidikan = async (e) => {
			e.preventDefault();
			if ($("#btnAddPendidikan").is(':visible')) {
				document.getElementById("btnAddPendidikan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPendidikan = await ApiAnggota.createPendidikan({
					jenjang: document.getElementById('selectJenjangPendidikan').value,
					nama_institusi_pendidikan: document.getElementById('textInstitusiPendidikan').value,
					tahun_lulus: document.getElementById('numberTahunLulus').value,
					nomor_ijazah: document.getElementById('textNomorIjazah').value,
					tanggal_ijazah: document.getElementById('dateTanggalIjazah').value,
					jenis_dokumen: document.getElementById('textJenisDokumen').value,
					imageIjazah: (DzonePendidikan.files.length <= 0) ? null : await this.dataUrlToFile(DzonePendidikan.files[0].dataURL, DzonePendidikan.files[0].name)


				});
				if (createPendidikan.status_code != 201) {
					NotificationModal.show(`${createPendidikan.message}`, 'error');
					document.getElementById("btnAddPendidikan").innerHTML = `<i class="fas fa-add"></i> Tambah Pendidikan`;
					$("#btnUpdatePendidikan").hide();
				} else {
					NotificationModal.show(`${createPendidikan.message}`, 'success');
					eventClear();
					document.getElementById("btnAddPendidikan").innerHTML = `<i class="fas fa-add"></i> Tambah Pendidikan`;
					$("#btnUpdatePendidikan").hide();
					$('#tableDataPendidikan').DataTable().ajax.reload();
				}
			} else {
				document.getElementById("btnUpdatePendidikan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updatePendidikan = await ApiAnggota.updatePendidikan({
					id: document.getElementById('textHiddenIdPendidikan').value,
					jenjang: document.getElementById('selectJenjangPendidikan').value,
					nama_institusi_pendidikan: document.getElementById('textInstitusiPendidikan').value,
					tahun_lulus: document.getElementById('numberTahunLulus').value,
					nomor_ijazah: document.getElementById('textNomorIjazah').value,
					tanggal_ijazah: document.getElementById('dateTanggalIjazah').value,
					jenis_dokumen: document.getElementById('textJenisDokumen').value,
					imageIjazah: (DzonePendidikan.files.length <= 0) ? null : await this.dataUrlToFile(DzonePendidikan.files[0].dataURL, DzonePendidikan.files[0].name)

				});

				if (updatePendidikan.status_code != 200) {
					NotificationModal.show(`${updatePendidikan.message}`, 'error');
					$("#btnAddPendidikan").hide();
					document.getElementById("btnUpdatePendidikan").innerHTML = `<i class="fas fa-edit"></i> Edit Pendidikan`;
				} else {
					NotificationModal.show(`${updatePendidikan.message}`, 'success');
					document.getElementById("btnUpdatePendidikan").innerHTML = `<i class="fas fa-edit"></i> Edit Pendidikan`;
					eventClear();
					$('#tableDataPendidikan').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-pendidikan').addEventListener('submit', eventCreatePendidikan);


		$('#tableDataPendidikan tbody').on('click', '#edit', function () {
			const table = $('#tableDataPendidikan').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenIdPendidikan').val(data.id);
			$('#selectJenjangPendidikan').val(data.jenjang);
			$('#textInstitusiPendidikan').val(data.nama_institusi_pendidikan);
			$('#numberTahunLulus').val(data.tahun_lulus);
			$('#textNomorIjazah').val(data.nomor_ijazah);
			var parts = data.tanggal_ijazah.split('-');
			var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
	  
			$('#dateTanggalIjazah').val(formattedDate);
			$("#btnAddPendidikan").hide();
			$("#btnUpdatePendidikan").show();

			DzonePendidikan.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "Ijazah", size: 12345 };
				DzonePendidikan.emit("addedfile", mockFile);
				DzonePendidikan.emit("thumbnail", mockFile, data.dokumen);
			}

		});

		$('#tableDataPendidikan tbody').on('click', '#delete', async function () {
			const table = $('#tableDataPendidikan').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Institusi </strong>: " + data.nama_institusi_pendidikan,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idPendidikan = data.id;

					const deletePendidikan = await ApiAnggota.deletePendidikan({
						id: idPendidikan
					});

					if (deletePendidikan.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePendidikan.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						$('#tableDataPendidikan').DataTable().ajax.reload();
						eventClear()
						const dataPekejaan = await ApiAnggota.getPekerjaan();


						var selectPekerjaan = document.getElementById("selectTempatKerjaSIP");
						var lengthOptionPekerjaan = selectPekerjaan.options.length;
						for (let i = lengthOptionPekerjaan - 1; i >= 0; i--) {
							selectPekerjaan.options[i] = null;
						}

						if (dataPekejaan.data == null) {
							document.getElementById("selectTempatKerjaSIP").add(new Option("Silahkan isi profile pekerjaan anda...", ""))
						} else {
							document.getElementById("selectTempatKerjaSIP").add(new Option("Pilih Salah Satu", ""))
							dataPekejaan.data.forEach(data => {
								document.getElementById("selectTempatKerjaSIP").add(new Option(data.nama_tempat_kerja, data.id))
							});
						}
					} else {
						swal.fire({
							title: 'Error',
							text: deletePendidikan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		const eventClear = async (e) => {
			document.getElementById("form-pendidikan").reset();
			$("#btnAddPendidikan").show();
			$("#btnUpdatePendidikan").hide();
			if (DzonePendidikan.files.length > 0) {
				DzonePendidikan.removeFile(DzonePendidikan.files[0])
			}
			DzonePendidikan.emit("removeThumbnailCustom");
		}

		document.getElementById("btnClearPendidikan").addEventListener("click", eventClear)

	},

	async _createPekerjaan() {



		let DzonePekerjaan = new Dropzone("#form-upload-pekerjaan", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});

				this.on("removeThumbnailCustom", function () {
					$('#form-upload-pekerjaan .dz-preview:hidden').remove();
					$('#form-upload-pekerjaan .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-pekerjaan").classList.remove('dz-started');
				});
			}
		});

		const eventCreatePekerjaan = async (e) => {


			e.preventDefault();


			if ($("#btnAddPekerjaan").is(':visible')) {
				document.getElementById("btnAddPekerjaan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPekerjaan = await ApiAnggota.createPekerjaan({
					nama_tempat_kerja: document.getElementById('textNamaTempatKerja').value,
					alamat_tempat_kerja: document.getElementById('textAlamatPekerjaan').value,
					telp_fax: document.getElementById('textTelpFax').value,
					phone_number: document.getElementById('textNoHP').value,
					email: document.getElementById('textEmailPekerjaan').value,
					jabatan: document.getElementById('textJabatan').value,
					jenis_tempat_kerja: document.getElementById('selectJenisPekerjaan').value,
					dari_tahun: document.getElementById('dateDariTahun').value,
					sampai_tahun: document.getElementById('dateSampaiTahun').value,
					pekerjaan_dokumen: (DzonePekerjaan.files.length <= 0) ? null : await this.dataUrlToFile(DzonePekerjaan.files[0].dataURL, DzonePekerjaan.files[0].name)
				});
				if (createPekerjaan.status_code != 201) {
					NotificationModal.show(`${createPekerjaan.message}`, 'error');
					document.getElementById("btnAddPekerjaan").innerHTML = `<i class="fas fa-add"></i> Tambah Pekerjaan`;
					$("#btnUpdatePekerjaan").hide();
				} else {
					NotificationModal.show(`${createPekerjaan.message}`, 'success');
					document.getElementById("btnAddPekerjaan").innerHTML = `<i class="fas fa-add"></i> Tambah Pekerjaan`;
					$("#btnUpdatePekerjaan").hide();
					$('#tableDataPekerjaan').DataTable().ajax.reload();
					eventClear()
				}

			} else {

				document.getElementById("btnUpdatePekerjaan").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updatePekerjaan = await ApiAnggota.updatePekerjaan({
					id: document.getElementById('textHiddenPekerjaan').value,
					nama_tempat_kerja: document.getElementById('textNamaTempatKerja').value,
					alamat_tempat_kerja: document.getElementById('textAlamatPekerjaan').value,
					telp_fax: document.getElementById('textTelpFax').value,
					phone_number: document.getElementById('textNoHP').value,
					email: document.getElementById('textEmailPekerjaan').value,
					jabatan: document.getElementById('textJabatan').value,
					jenis_tempat_kerja: document.getElementById('selectJenisPekerjaan').value,
					dari_tahun: document.getElementById('dateDariTahun').value,
					sampai_tahun: document.getElementById('dateSampaiTahun').value,
					pekerjaan_dokumen: (DzonePekerjaan.files.length <= 0) ? null : await this.dataUrlToFile(DzonePekerjaan.files[0].dataURL, DzonePekerjaan.files[0].name)

				});

				if (updatePekerjaan.status_code != 200) {
					NotificationModal.show(`${updatePekerjaan.message}`, 'error');
					$("#btnAddPekerjaan").hide();
					document.getElementById("btnUpdatePekerjaan").innerHTML = `<i class="fas fa-edit"></i> Edit Pekerjaan`;
				} else {
					NotificationModal.show(`${updatePekerjaan.message}`, 'success');
					document.getElementById("btnUpdatePekerjaan").innerHTML = `<i class="fas fa-edit"></i> Edit Pekerjaan`;
					$("#btnAddPekerjaan").hide();
					eventClear();
					$('#tableDataPekerjaan').DataTable().ajax.reload();
				}
			}

			this._getPekerjaanSIP();
			e.preventDefault();
		}
		document.getElementById('form-pekerjaan').addEventListener('submit', eventCreatePekerjaan);


		$('#tableDataPekerjaan tbody').on('click', '#edit', function () {
			const table = $('#tableDataPekerjaan').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenPekerjaan').val(data.id);
			$('#textNamaTempatKerja').val(data.nama_tempat_kerja);
			$('#selectJenisPekerjaan').val(data.jenis_tempat_kerja);
			$('#textAlamatPekerjaan').val(data.alamat_tempat_kerja);
			$('#textJabatan').val(data.jabatan);
			$('#dateDariTahun').val(data.dari_tahun);
			$('#dateSampaiTahun').val(data.sampai_tahun);
			$("#btnAddPekerjaan").hide();
			$("#btnUpdatePekerjaan").show();
			$('#textEmailPekerjaan').val(data.email);
			$('#textTelpFax').val(data.telp_fax);
			$('#textNoHP').val(data.phone_number);


			DzonePekerjaan.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "Ijazah", size: 12345 };
				DzonePekerjaan.emit("addedfile", mockFile);
				DzonePekerjaan.emit("thumbnail", mockFile, data.dokumen);
			}


		});

		$('#tableDataPekerjaan tbody').on('click', '#delete', async function () {
			const table = $('#tableDataPekerjaan').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Institusi </strong>: " + data.nama_tempat_kerja,
				icon: 'warning',
				showCancelButton: true,
				showSpinner: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idPekerjaan = data.id;

					const deletePekerjaan = await ApiAnggota.deletePekerjaan({
						id: idPekerjaan
					});

					if (deletePekerjaan.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deletePekerjaan.message,
							icon: 'success',
							showConfirmButton: false,
							showSpinner: true,
							timer: 1000
						});
						//refres manual 
						$('#tableDataPekerjaan').DataTable().ajax.reload();
						eventClear();
						const dataPekejaan = await ApiAnggota.getPekerjaan();


						var selectPekerjaan = document.getElementById("selectTempatKerjaSIP");
						var lengthOptionPekerjaan = selectPekerjaan.options.length;
						for (let i = lengthOptionPekerjaan - 1; i >= 0; i--) {
							selectPekerjaan.options[i] = null;
						}
						if (dataPekejaan.data == null) {
							document.getElementById("selectTempatKerjaSIP").add(new Option("Silahkan isi profile pekerjaan anda...", ""))
						} else {
							document.getElementById("selectTempatKerjaSIP").add(new Option("Pilih Salah Satu", ""))
							dataPekejaan.data.forEach(data => {
								document.getElementById("selectTempatKerjaSIP").add(new Option(data.nama_tempat_kerja, data.id))
							});
						}


					} else {
						swal.fire({
							title: 'Error',
							text: deletePekerjaan.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});

		});


		const eventClear = async (e) => {
			document.getElementById("form-pekerjaan").reset();
			$("#btnAddPekerjaan").show();
			$("#btnUpdatePekerjaan").hide();
			if (DzonePekerjaan.files.length > 0) {
				DzonePekerjaan.removeFile(DzonePekerjaan.files[0])
			}
			DzonePekerjaan.emit("removeThumbnailCustom");
		}



		document.getElementById("btnClearPekerjaan").addEventListener("click", eventClear)
	},

	async _createOrganisasi() {

		let DzoneOrganisasi = new Dropzone("#form-upload-organisasi", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-organisasi .dz-preview:hidden').remove();
					$('#form-upload-organisasi .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-organisasi").classList.remove('dz-started');
				});
			}
		});

		const eventCreateOrganisasi = async (e) => {
			e.preventDefault();

			if ($("#btnAddOrganisasi").is(':visible')) {
				document.getElementById("btnAddOrganisasi").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createOrganisasi = await ApiAnggota.createOrganisasi({
					nama_organisasi: document.getElementById('textNamaOrganisasi').value,
					alamat_organisasi: document.getElementById('textAlamatOrganisasi').value,
					jabatan: document.getElementById('textJabatanOrganisasi').value,
					dari_tahun: document.getElementById('dateDariTahunOrganisasi').value,
					sampai_tahun: document.getElementById('dateSampaiTahunOrganisasi').value,
					organisasi_dokumen: (DzoneOrganisasi.files.length <= 0) ? null : await this.dataUrlToFile(DzoneOrganisasi.files[0].dataURL, DzoneOrganisasi.files[0].name)
				});
				if (createOrganisasi.status_code != 201) {
					NotificationModal.show(`${createOrganisasi.message}`, 'error');
					document.getElementById("btnAddOrganisasi").innerHTML = `<i class="fas fa-add"></i> Tambah Organisasi`;
					$("#btnUpdateOrganisasi").hide();
				} else {
					NotificationModal.show(`${createOrganisasi.message}`, 'success');
					eventClear();
					document.getElementById("btnAddOrganisasi").innerHTML = `<i class="fas fa-add"></i> Tambah Organisasi`;
					$("#btnUpdateOrganisasi").hide();
					$('#tableDataRiwayatOrganisasi').DataTable().ajax.reload();
				}

			} else {

				document.getElementById("btnUpdateOrganisasi").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateOrganisasi = await ApiAnggota.updateOrganisasi({
					id: document.getElementById('textHiddenOrganisasi').value,
					nama_organisasi: document.getElementById('textNamaOrganisasi').value,
					alamat_organisasi: document.getElementById('textAlamatOrganisasi').value,
					jabatan: document.getElementById('textJabatanOrganisasi').value,
					dari_tahun: document.getElementById('dateDariTahunOrganisasi').value,
					sampai_tahun: document.getElementById('dateSampaiTahunOrganisasi').value,
					organisasi_dokumen: (DzoneOrganisasi.files.length <= 0) ? null : await this.dataUrlToFile(DzoneOrganisasi.files[0].dataURL, DzoneOrganisasi.files[0].name)

				});

				if (updateOrganisasi.status_code != 200) {
					NotificationModal.show(`${updateOrganisasi.message}`, 'error');
					$("#btnAddOrganisasi").hide();
					document.getElementById("btnUpdateOrganisasi").innerHTML = `<i class="fas fa-edit"></i> Edit Organisasi`;
				} else {
					NotificationModal.show(`${updateOrganisasi.message}`, 'success');
					eventClear();
					document.getElementById("btnUpdateOrganisasi").innerHTML = `<i class="fas fa-edit"></i> Edit Organisasi`;
					$("#btnAddOrganisasi").hide();
					$('#tableDataRiwayatOrganisasi').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-organisasi').addEventListener('submit', eventCreateOrganisasi);


		$('#tableDataRiwayatOrganisasi tbody').on('click', '#edit', function () {
			const table = $('#tableDataRiwayatOrganisasi').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenOrganisasi').val(data.id);
			$('#textNamaOrganisasi').val(data.nama_organisasi);
			$('#textAlamatOrganisasi').val(data.alamat_organisasi);
			$('#textJabatanOrganisasi').val(data.jabatan);
			$('#dateDariTahunOrganisasi').val(data.dari_tahun);
			$('#dateSampaiTahunOrganisasi').val(data.sampai_tahun);
			$("#btnAddOrganisasi").hide();
			$("#btnUpdateOrganisasi").show();


			DzoneOrganisasi.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "Organisasi", size: 12345 };
				DzoneOrganisasi.emit("addedfile", mockFile);
				DzoneOrganisasi.emit("thumbnail", mockFile, data.dokumen);
			}


		});

		$('#tableDataRiwayatOrganisasi tbody').on('click', '#delete', async function () {
			const table = $('#tableDataRiwayatOrganisasi').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nama Organisasi </strong>: " + data.nama_organisasi,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idOrganisasi = data.id;

					const deleteOrganisasi = await ApiAnggota.deleteOrganisasi({
						id: idOrganisasi
					});

					if (deleteOrganisasi.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteOrganisasi.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						eventClear();
						$('#tableDataRiwayatOrganisasi').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteOrganisasi.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		const eventClear = async (e) => {
			document.getElementById("form-organisasi").reset();
			$("#btnAddOrganisasi").show();
			$("#btnUpdateOrganisasi").hide();
			if (DzoneOrganisasi.files.length > 0) {
				DzoneOrganisasi.removeFile(DzoneOrganisasi.files[0])
			}
			DzoneOrganisasi.emit("removeThumbnailCustom");
		}

		document.getElementById("btnClearOrganisasi").addEventListener("click", eventClear)
	},

	async _createSTRTTK() {

		let DzoneSTRTTK = new Dropzone("#form-upload-strttk", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-strttk .dz-preview:hidden').remove()
					$('#form-upload-strttk .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-strttk").classList.remove('dz-started');
				});
			}
		});

		const eventCreateSTRTK = async (e) => {


			e.preventDefault();

			let berlakuSampaiSTRTTK = '';
			let jenisSTRTTK = document.getElementById('selectJenisSTRTTK').value;

			if (jenisSTRTTK == 'old') {
				berlakuSampaiSTRTTK = document.getElementById('dateBerlakuSampai').value;
			}else {
				berlakuSampaiSTRTTK = 'lifetime'
			}

			if ($("#btnAddSTRTTK").is(':visible')) {
				document.getElementById("btnAddSTRTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSTRTTK = await ApiAnggota.createSTRTTK({
					nomor_strttk: document.getElementById('textNomorSTRTTK').value,
					tanggal: document.getElementById('dateTanggalSTRTTK').value,
					berlaku_sampai: berlakuSampaiSTRTTK,
					strttk_dokumen: (DzoneSTRTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSTRTTK.files[0].dataURL, DzoneSTRTTK.files[0].name)
				});
				if (createSTRTTK.status_code != 201) {
					NotificationModal.show(`${createSTRTTK.message}`, 'error');
					document.getElementById("btnAddSTRTTK").innerHTML = `<i class="fas fa-add"></i> Tambah STRTTK`;
					$("#btnUpdateSTRTTK").hide();
				} else {
					NotificationModal.show(`${createSTRTTK.message}`, 'success');
					document.getElementById("btnAddSTRTTK").innerHTML = `<i class="fas fa-add"></i> Tambah STRTTK`;
					$("#btnUpdateSTRTTK").hide();
					eventClear();
					$('#tableSTRTTK').DataTable().ajax.reload();
				}

			} else {

				document.getElementById("btnUpdateSTRTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSTRTTK = await ApiAnggota.updateSTRTTK({
					id: document.getElementById('textHiddenSTRTTK').value,
					nomor_strttk: document.getElementById('textNomorSTRTTK').value,
					tanggal: document.getElementById('dateTanggalSTRTTK').value,
					berlaku_sampai: berlakuSampaiSTRTTK,

					strttk_dokumen: (DzoneSTRTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSTRTTK.files[0].dataURL, DzoneSTRTTK.files[0].name)

				});

				if (updateSTRTTK.status_code != 200) {
					NotificationModal.show(`${updateSTRTTK.message}`, 'error');
					$("#btnAddSTRTTK").hide();
					document.getElementById("btnUpdateSTRTTK").innerHTML = `<i class="fas fa-edit"></i> Edit STRTTK`;
				} else {
					NotificationModal.show(`${updateSTRTTK.message}`, 'success');
					document.getElementById("btnUpdateSTRTTK").innerHTML = `<i class="fas fa-edit"></i> Edit STRTTK`;
					$("#btnAddSTRTTK").hide();
					eventClear();
					$('#tableSTRTTK').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-strttk').addEventListener('submit', eventCreateSTRTK);


		$('#tableSTRTTK tbody').on('click', '#edit', async function () {
			const table = $('#tableSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let jenisSTRTTK = '';
			if (data.berlaku_sampai == 'Seumur Hidup') {
				jenisSTRTTK = 'new'
			}else {
				jenisSTRTTK = 'old'
				$('#dateBerlakuSampai').val(data.berlaku_sampai);
			}
			if (jenisSTRTTK == 'old') {
				document.getElementById('oldForm').classList.remove('d-none');
				document.getElementById('newForm').classList.add('d-none');
			}else {
				document.getElementById('oldForm').classList.add('d-none');
				document.getElementById('newForm').classList.remove('d-none');
			}
	
			
			$('#selectJenisSTRTTK').val(jenisSTRTTK);
			$('#textHiddenSTRTTK').val(data.id);
			$('#textNomorSTRTTK').val(data.nomor_strttk);
			$('#dateTanggalSTRTTK').val(await OverviewInitiator._ubahFormatTanggal(data.tanggal));
			$("#btnAddSTRTTK").hide();
			$("#btnUpdateSTRTTK").show();


			DzoneSTRTTK.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "STRTTK", size: 12345 };
				DzoneSTRTTK.emit("addedfile", mockFile);
				DzoneSTRTTK.emit("thumbnail", mockFile, data.dokumen);
			}


		});

		$('#tableSTRTTK tbody').on('click', '#delete', async function () {
			const table = $('#tableSTRTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nomor STRTTK </strong>: " + data.nomor_strttk,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idSTRTTK = data.id;

					const deleteSTRTTK = await ApiAnggota.deleteSTRTTK({
						id: idSTRTTK
					});

					if (deleteSTRTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSTRTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						eventClear();
						$('#tableSTRTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSTRTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});


		const eventClear = async (e) => {
			document.getElementById("form-strttk").reset();
			$("#btnAddSTRTTK").show();
			$("#btnUpdateSTRTTK").hide();
			if (DzoneSTRTTK.files.length > 0) {
				DzoneSTRTTK.removeFile(DzoneSTRTTK.files[0])
			}
			DzoneSTRTTK.emit("removeThumbnailCustom");

		}

		document.getElementById("btnClearSTRTTK").addEventListener("click", eventClear)
	},
	async _createSERKOM() {

		let DzoneSERKOM = new Dropzone("#form-upload-serkom", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {
					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-serkom .dz-preview:hidden').remove();
					$('#form-upload-serkom .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-serkom").classList.remove('dz-started');
				});
			}
		});

		const eventCreateSERKOM = async (e) => {


			e.preventDefault();

			if ($("#btnAddSERKOM").is(':visible')) {
				document.getElementById("btnAddSERKOM").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSERKOM = await ApiAnggota.createSERKOM({
					nomor_serkom: document.getElementById('textNomorSERKOM').value,
					tanggal: document.getElementById('dateTanggalSERKOM').value,
					berlaku_sampai: document.getElementById('dateBerlakuSampaiSERKOM').value,
					serkom_dokumen: (DzoneSERKOM.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSERKOM.files[0].dataURL, DzoneSERKOM.files[0].name)
				});
				if (createSERKOM.status_code != 201) {
					NotificationModal.show(`${createSERKOM.message}`, 'error');
					document.getElementById("btnAddSERKOM").innerHTML = `<i class="fas fa-add"></i> Tambah SERKOM`;
					$("#btnUpdateSERKOM").hide();
				} else {
					NotificationModal.show(`${createSERKOM.message}`, 'success');
					document.getElementById("btnAddSERKOM").innerHTML = `<i class="fas fa-add"></i> Tambah SERKOM`;
					$("#btnUpdateSERKOM").hide();
					eventClear();
					$('#tableSERKOM').DataTable().ajax.reload();
				}

			} else {

				document.getElementById("btnUpdateSERKOM").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSERKOM = await ApiAnggota.updateSERKOM({
					id: document.getElementById('textHiddenSERKOM').value,
					nomor_serkom: document.getElementById('textNomorSERKOM').value,
					tanggal: document.getElementById('dateTanggalSERKOM').value,
					berlaku_sampai: document.getElementById('dateBerlakuSampaiSERKOM').value,

					serkom_dokumen: (DzoneSERKOM.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSERKOM.files[0].dataURL, DzoneSERKOM.files[0].name)

				});

				if (updateSERKOM.status_code != 200) {
					NotificationModal.show(`${updateSERKOM.message}`, 'error');
					$("#btnAddSERKOM").hide();
					document.getElementById("btnUpdateSERKOM").innerHTML = `<i class="fas fa-edit"></i> Edit STRTTK`;
				} else {
					NotificationModal.show(`${updateSERKOM.message}`, 'success');
					document.getElementById("btnUpdateSERKOM").innerHTML = `<i class="fas fa-edit"></i> Edit STRTTK`;
					$("#btnAddSERKOM").hide();
					eventClear();
					$('#tableSERKOM').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-serkom').addEventListener('submit', eventCreateSERKOM);


		$('#tableSERKOM tbody').on('click', '#edit', async function () {
			const table = $('#tableSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenSERKOM').val(data.id);
			$('#textNomorSERKOM').val(data.nomor_serkom);
			$('#dateTanggalSERKOM').val(await OverviewInitiator._ubahFormatTanggal(data.tanggal));
			$('#dateBerlakuSampaiSERKOM').val(await OverviewInitiator._ubahFormatTanggal(data.berlaku_sampai));
			$("#btnAddSERKOM").hide();
			$("#btnUpdateSERKOM").show();


			DzoneSERKOM.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "SERKOM", size: 12345 };
				DzoneSERKOM.emit("addedfile", mockFile);
				DzoneSERKOM.emit("thumbnail", mockFile, data.dokumen);
			}


		});

		$('#tableSERKOM tbody').on('click', '#delete', async function () {
			const table = $('#tableSERKOM').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nomor SERKOM </strong>: " + data.nomor_serkom,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idSERKOM = data.id;

					const deleteSERKOM = await ApiAnggota.deleteSERKOM({
						id: idSERKOM
					});

					if (deleteSERKOM.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							text: deleteSERKOM.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						eventClear();
						$('#tableSERKOM').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSERKOM.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});


		const eventClear = async (e) => {
			document.getElementById("form-serkom").reset();
			$("#btnAddSERKOM").show();
			$("#btnUpdateSERKOM").hide();
			if (DzoneSERKOM.files.length > 0) {
				DzoneSERKOM.removeFile(DzoneSERKOM.files[0])
			}
			DzoneSERKOM.emit("removeThumbnailCustom");
		}

		document.getElementById("btnClearSERKOM").addEventListener("click", eventClear)
	},
	
	async _ubahFormatTanggal(tanggal){
		// Membagi tanggal menjadi komponen hari, bulan, dan tahun
		var komponenTanggal = tanggal.split("-");
		var hari = komponenTanggal[0];
		var bulan = komponenTanggal[1];
		var tahun = komponenTanggal[2];
	  
		// Membentuk tanggal baru dalam format "yyyy-MM-dd"
		var tanggalBaru = tahun + "-" + bulan + "-" + hari;
	  
		return tanggalBaru;
	},

	async _createSIPTTK() {

		let DzoneSIPTTK = new Dropzone("#form-upload-sipttk", {
			url: "/file/post",
			uploadMultiple: false,
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation: "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function () {
				this.on("maxfilesexceeded", function (file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function (file, message) {

					this.removeFile(file);
				});
				this.on("addedfiles", function (files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
				});
				this.on("removeThumbnailCustom", function () {
					$('#form-upload-sipttk .dz-preview:hidden').remove();
					$('#form-upload-sipttk .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-sipttk").classList.remove('dz-started');
				});
			}
		});

		const eventCreateSIPTTK = async (e) => {


			e.preventDefault();

			if ($("#btnAddSIPTTK").is(':visible')) {
				document.getElementById("btnAddSIPTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createSIPTTK = await ApiAnggota.createSIPTTK({
					nomor_strttk: document.getElementById('textNomorSTRTTKSIP').value,
					nomor_sipttk: document.getElementById('textNomorSIPTTK').value,
					jenis_sipttk: document.getElementById('selectJenisSIPTTK').value,
					tempat_kerja_id: document.getElementById('selectTempatKerjaSIP').value,
					tanggal: document.getElementById('dateTanggalSIPTTK').value,
					berlaku_sampai: document.getElementById('dateBerlakuSampaiSIPTTK').value,
					nama_apoteker_supervisi: document.getElementById('textNamaApotekerSupervisi').value,
					nomor_stra_apoteker: document.getElementById('textNomorSTRAApoteker').value,
					sipttk_dokumen: (DzoneSIPTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSIPTTK.files[0].dataURL, DzoneSIPTTK.files[0].name)
				});
				if (createSIPTTK.status_code != 201) {
					NotificationModal.show(`${createSIPTTK.message}`, 'error');
					document.getElementById("btnAddSIPTTK").innerHTML = `<i class="fas fa-add"></i> Tambah SIP / TTK`;
					$("#btnUpdateSIPTTK").hide();
				} else {
					NotificationModal.show(`${createSIPTTK.message}`, 'success');
					document.getElementById("btnAddSIPTTK").innerHTML = `<i class="fas fa-add"></i> Tambah SIP / TTK`;
					$("#btnUpdateSIPTTK").hide();
					eventClear();
					$('#tableSIPTTK').DataTable().ajax.reload();
				}

			} else {

				document.getElementById("btnUpdateSIPTTK").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const updateSIPTTK = await ApiAnggota.updateSIPTTK({
					id: document.getElementById('textHiddenSIPTTK').value,
					nomor_strttk: document.getElementById('textNomorSTRTTKSIP').value,
					nomor_sipttk: document.getElementById('textNomorSIPTTK').value,
					jenis_sipttk: document.getElementById('selectJenisSIPTTK').value,
					tempat_kerja_id: document.getElementById('selectTempatKerjaSIP').value,
					tanggal: document.getElementById('dateTanggalSIPTTK').value,
					berlaku_sampai: document.getElementById('dateBerlakuSampaiSIPTTK').value,
					nama_apoteker_supervisi: document.getElementById('textNamaApotekerSupervisi').value,
					nomor_stra_apoteker: document.getElementById('textNomorSTRAApoteker').value,
					sipttk_dokumen: (DzoneSIPTTK.files.length <= 0) ? null : await this.dataUrlToFile(DzoneSIPTTK.files[0].dataURL, DzoneSIPTTK.files[0].name)

				});

				if (updateSIPTTK.status_code != 200) {
					NotificationModal.show(`${updateSIPTTK.message}`, 'error');
					$("#btnAddSIPTTK").hide();
					eventClear();
					document.getElementById("btnUpdateSIPTTK").innerHTML = `<i class="fas fa-edit"></i> Edit SIP / TTK`;
				} else {
					NotificationModal.show(`${updateSIPTTK.message}`, 'success');
					document.getElementById("btnUpdateSIPTTK").innerHTML = `<i class="fas fa-edit"></i> Edit SIP / TTK`;
					$("#btnAddSIPTTK").hide();
					eventClear();
					$('#tableSIPTKK').DataTable().ajax.reload();
				}
			}

			e.preventDefault();
		}
		document.getElementById('form-sipttk').addEventListener('submit', eventCreateSIPTTK);


		$('#tableSIPTTK tbody').on('click', '#edit', async function () {
			const table = $('#tableSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();
			$('#textHiddenSIPTTK').val(data.id);
			$('#textNomorSTRTTKSIP').val(data.nomor_strttk);
			$('#textNomorSIPTTK').val(data.nomor_sipttk);
			$('#selectJenisSIPTTK').val(data.jenis_sipttk);
			$('#dateTanggalSIPTTK').val(await OverviewInitiator._ubahFormatTanggal(data.tanggal));
			$('#dateBerlakuSampaiSIPTTK').val(await OverviewInitiator._ubahFormatTanggal(data.berlaku_sampai));
			$('#selectTempatKerjaSIP').val(data.tempat_kerja.id);
			$('#textAlamatTempatKerjaSIP').val(data.tempat_kerja.alamat_tempat_kerja);
			$('#textNamaApotekerSupervisi').val(data.nama_apoteker_supervisi);
			$('#textNomorSTRAApoteker').val(data.nomor_stra_apoteker);
			$("#btnAddSIPTTK").hide();
			$("#btnUpdateSIPTTK").show();


			DzoneSIPTTK.emit("removeThumbnailCustom");
			if (data.dokumen != "") {
				var mockFile = { name: "SIPTTK", size: 12345 };
				DzoneSIPTTK.emit("addedfile", mockFile);
				DzoneSIPTTK.emit("thumbnail", mockFile, data.dokumen);
			}


		});

		$('#tableSIPTTK tbody').on('click', '#delete', async function () {
			const table = $('#tableSIPTTK').DataTable();
			let data = table.row($(this).parents('tr')).data();


			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong>Nomor SIP TTK </strong>: " + data.nomor_sipttk,
				icon: 'warning',
				showCancelButton: true,
				showSpinner: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!'
			}).then(async (result) => {
				if (result.value) {
					let idSIPTTK = data.id;

					const deleteSIPTTK = await ApiAnggota.deleteSIPTTK({
						id: idSIPTTK
					});

					if (deleteSIPTTK.status_code == '200') {
						swal.fire({
							title: 'Hapus',
							showSpinner: true,
							text: deleteSIPTTK.message,
							icon: 'success',
							showConfirmButton: false,
							timer: 1000
						});
						//refres manual 
						eventClear();
						$('#tableSIPTTK').DataTable().ajax.reload();
					} else {
						swal.fire({
							title: 'Error',
							text: deleteSIPTTK.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});


		const eventClear = async (e) => {
			document.getElementById("form-sipttk").reset();
			$("#btnAddSIPTTK").show();
			$("#btnUpdateSIPTTK").hide();
			if (DzoneSIPTTK.files.length > 0) {
				DzoneSIPTTK.removeFile(DzoneSIPTTK.files[0])
			}
			DzoneSIPTTK.emit("removeThumbnailCustom");
		}

		document.getElementById("btnClearSIPTTK").addEventListener("click", eventClear)
	},

	async _updateDataDomicille() {
		const eventUpdateDomicille = async (e) => {
			e.preventDefault();

			document.getElementById("btnUpdateDomisili").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const udpdateDataDomicille = await ApiAnggota.updateDataDomicille({
				address: document.getElementById('textAlamatDomisili').value,
				village_id: document.getElementById('selectKelurahanDomisili').value,
				sub_regency_id: document.getElementById('selectKecamatanDomisili').value,
				regency_id: document.getElementById('selectKotaDomisili').value,
				province_id: document.getElementById('selectProvinsiDomisili').value,

			});

			if (udpdateDataDomicille.status_code != 200) {
				NotificationModal.show(`${udpdateDataDomicille.message}`, 'error');
				document.getElementById("btnUpdateDomisili").innerHTML = `<i class="fas fa-edit"></i> Update Data Domisili`;
			} else {
				NotificationModal.show(`${udpdateDataDomicille.message}`, 'success');
				document.getElementById("btnUpdateDomisili").innerHTML = `<i class="fas fa-edit"></i> Update Data Domisili`;
			}


			e.preventDefault();
		}
		document.getElementById('form-data-domisili').addEventListener('submit', eventUpdateDomicille);
	},

	async _clearFormPendidikan() {

	},
	async _clearFormPekerjaan() {
		const eventClear = async (e) => {
			document.getElementById("form-pekerjaan").reset();
			$("#btnAddPekerjaan").show();
			$("#btnUpdatePekerjaan").hide();

		}

		document.getElementById("btnClearPekerjaan").addEventListener("click", eventClear)
	},
	async _clearFormOrganisasi() {

	},
	async _clearFormSTRTTK() {
		const eventClear = async (e) => {
			document.getElementById("form-strttk").reset();
			$("#btnAddSTRTTK").show();
			$("#btnUpdateSTRTTK").hide();
		}

		document.getElementById("btnClearSTRTTK").addEventListener("click", eventClear)
	},
	async _getPekerjaanSIP() {

		const dataPekejaan = await ApiAnggota.getPekerjaan();
		$("#textAlamatTempatKerjaSIP").val(null);

		var selectPekerjaan = document.getElementById("selectTempatKerjaSIP");
		var lengthOptionPekerjaan = selectPekerjaan.options.length;
		for (let i = lengthOptionPekerjaan - 1; i >= 0; i--) {
			selectPekerjaan.options[i] = null;
		}
		if (dataPekejaan.data == null) {
			document.getElementById("selectTempatKerjaSIP").add(new Option("Silahkan isi profile pekerjaan anda...", ""))
		} else {
			document.getElementById("selectTempatKerjaSIP").add(new Option("Pilih Salah Satu", ""))
			dataPekejaan.data.forEach(data => {
				document.getElementById("selectTempatKerjaSIP").add(new Option(data.nama_tempat_kerja, data.id))
			});
		}

		const evenChange = async (e) => {
			$("#textAlamatTempatKerjaSIP").html("Loading...");
			let valueSelected = document.getElementById("selectTempatKerjaSIP").value;
			const dataTempatKerja = await ApiAnggota.getPekerjaanById(valueSelected);

			$("#textAlamatTempatKerjaSIP").val(dataTempatKerja.data.alamat_tempat_kerja);
			e.preventDefault();
		}

		document.getElementById("selectTempatKerjaSIP").addEventListener("change", evenChange)



	},
	async _refreshPekerjaan() {
		const eventRefreshPekerjaan = async (e) => {
			this._getPekerjaanSIP();
		}

		document.getElementById("btnRefreshPekerjaan").addEventListener("click", eventRefreshPekerjaan);
	},
	async initProvinceValue() {
		const dataProvince = await ApiRegister.getProvinceKemendagri()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectProvinsi").add(new Option(data.name, data.code))
			});
		}

		const eventChange = async (e) => {
			document.getElementById("selectKota").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectProvinsi").value
			const dataRegistry = await ApiRegister.getRegistryKemendagri(valueSelected)


			var selectPC = document.getElementById("selectKota");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			var selectKecamatan = document.getElementById("selectKecamatan");
			var lengthOptionKecamatan = selectKecamatan.options.length;
			for (let i = lengthOptionKecamatan - 1; i >= 0; i--) {
				selectKecamatan.options[i] = null;
			}

			var selectKelurahan = document.getElementById("selectKelurahan");
			var lengthOptionKelurahan = selectKelurahan.options.length;
			for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
				selectKelurahan.options[i] = null;
			}




			document.getElementById("selectKota").add((new Option("Kabupaten / Kota", "")))
			document.getElementById("selectKecamatan").add((new Option("Kecamatan", "")))
			document.getElementById("selectKelurahan").add((new Option("Kelurahan / Desa", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectKota").add(new Option(data.name, data.city_code))
			});

			e.preventDefault();
		}

		const eventChangeSub = async (x) => {
			let valueSelected = document.getElementById("selectKota").value
			const dataSubRegency = await ApiRegister.getSubRegencyKemendagri(valueSelected)

			var selectKecamatan = document.getElementById("selectKecamatan");
			var lengthOptionKecamatan = selectKecamatan.options.length;
			for (let i = lengthOptionKecamatan - 1; i >= 0; i--) {
				selectKecamatan.options[i] = null;
			}


			var selectKelurahan = document.getElementById("selectKelurahan");
			var lengthOptionKelurahan = selectKelurahan.options.length;
			for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
				selectKelurahan.options[i] = null;
			}

			document.getElementById("selectKecamatan").add((new Option("Kecamatan", "")))

			document.getElementById("selectKelurahan").add((new Option("Kelurahan / Desa", "")))
			dataSubRegency.data.forEach(data => {
				document.getElementById("selectKecamatan").add(new Option(data.name, data.district_code))
			});


			x.preventDefault();
		}


		const eventChangeVillage = async (y) => {
			let valueSelectedKecamatan = document.getElementById("selectKecamatan").value
			const dataVillage = await ApiRegister.getVillageKemendagri(valueSelectedKecamatan)
			var selectKelurahan = document.getElementById("selectKelurahan");
			var lengthOptionKelurahan = selectKelurahan.options.length;
			for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
				selectKelurahan.options[i] = null;
			}

			document.getElementById("selectKelurahan").add((new Option("Kelurahan / Desa", "")))
			dataVillage.data.forEach(data => {
				document.getElementById("selectKelurahan").add(new Option(data.name, data.village_code))
			});


			y.preventDefault();
		}
		document.getElementById("selectKecamatan").addEventListener("change", eventChangeVillage);

		document.getElementById("selectKota").addEventListener("change", eventChangeSub);

		document.getElementById("selectProvinsi").addEventListener("change", eventChange);


	},

	async initProvinceValueDomicille() {
		const dataProvince = await ApiRegister.getProvinceKemendagri()
		if (dataProvince.status_code == 200) {
			dataProvince.data.forEach(data => {
				document.getElementById("selectProvinsiDomisili").add(new Option(data.name, data.code))
			});
		}
		
		var selectPC = document.getElementById("selectKotaDomisili");
		var lengthOptionPc = selectPC.options.length;
		for (let i = lengthOptionPc - 1; i >= 0; i--) {
			selectPC.options[i] = null;
		}

		const eventChange = async (e) => {
			document.getElementById("selectKotaDomisili").add((new Option("Loading Kabupaten / Kota ...", "")))
			let valueSelected = document.getElementById("selectProvinsiDomisili").value
			const dataRegistry = await ApiRegister.getRegistryKemendagri(valueSelected)

			var selectPC = document.getElementById("selectKotaDomisili");
			var lengthOptionPc = selectPC.options.length;
			for (let i = lengthOptionPc - 1; i >= 0; i--) {
				selectPC.options[i] = null;
			}

			var selectKecamatan = document.getElementById("selectKecamatanDomisili");
			var lengthOptionKecamatan = selectKecamatan.options.length;
			for (let i = lengthOptionKecamatan - 1; i >= 0; i--) {
				selectKecamatan.options[i] = null;
			}

			var selectKelurahan = document.getElementById("selectKelurahanDomisili");
			var lengthOptionKelurahan = selectKelurahan.options.length;
			for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
				selectKelurahan.options[i] = null;
			}

			document.getElementById("selectKotaDomisili").add((new Option("Kabupaten / Kota", "")))
			document.getElementById("selectKecamatanDomisili").add((new Option("Kecamatan", "")))
			document.getElementById("selectKelurahanDomisili").add((new Option("Kelurahan / Desa", "")))
			dataRegistry.data.forEach(data => {
				document.getElementById("selectKotaDomisili").add(new Option(data.name, data.city_code))
			});

			e.preventDefault();
		}

		const eventChangeSub = async (x) => {
			let valueSelected = document.getElementById("selectKotaDomisili").value
			const dataSubRegency = await ApiRegister.getSubRegencyKemendagri(valueSelected)

			var selectKecamatan = document.getElementById("selectKecamatanDomisili");
			var lengthOptionKecamatan = selectKecamatan.options.length;
			for (let i = lengthOptionKecamatan - 1; i >= 0; i--) {
				selectKecamatan.options[i] = null;
			}

			document.getElementById("selectKecamatanDomisili").add((new Option("Kecamatan", "")))
			dataSubRegency.data.forEach(data => {
				document.getElementById("selectKecamatanDomisili").add(new Option(data.name, data.district_code))
			});


			x.preventDefault();
		}


		const eventChangeVillage = async (y) => {
			let valueSelectedKecamatan = document.getElementById("selectKecamatanDomisili").value
			const dataVillage = await ApiRegister.getVillageKemendagri(valueSelectedKecamatan)
			var selectKelurahan = document.getElementById("selectKelurahanDomisili");
			var lengthOptionKelurahan = selectKelurahan.options.length;
			for (let i = lengthOptionKelurahan - 1; i >= 0; i--) {
				selectKelurahan.options[i] = null;
			}

			document.getElementById("selectKelurahanDomisili").add((new Option("Kelurahan / Desa", "")))
			dataVillage.data.forEach(data => {
				document.getElementById("selectKelurahanDomisili").add(new Option(data.name, data.village_code))
			});


			y.preventDefault();
		}
		document.getElementById("selectKecamatanDomisili").addEventListener("change", eventChangeVillage);

		document.getElementById("selectKotaDomisili").addEventListener("change", eventChangeSub);

		document.getElementById("selectProvinsiDomisili").addEventListener("change", eventChange);


	},

	async initCommentUser() {
		let getContentAdmin = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
		}

		let getContentUser = (user) => {
			let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
			return `
					<div style="width:100%">
						<div style="width:10%;" class="float-right">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-right text-dark text-left" style="width:88%;">
							<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
		}


		let response = await ApiAnggota.getCommentarKTAN(1);
		let dataComment = response.data;
		dataComment.reverse();


		let getAllContent = async () => {
			let elmHtml = ``
			await dataComment.forEach(async element => {
				elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
			});
			return elmHtml
		}

		if (response.data == undefined || response.data.length <= 0) {
			document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
		} else {
			document.getElementById("content-comment").innerHTML = await getAllContent();

			setTimeout(() => {
				var elem = document.getElementById('content-comment');
				elem.scrollTop = elem.scrollHeight;
			}, 400);
		}

	},

	async initEventCommentar() {
		const eventSendCommentar = async (e) => {
			e.preventDefault();
			document.getElementById("btnSendComment").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("textKomentar").value
			const response = await ApiAnggota.sendCommentarKTAN(content, "ktan");
			if (response.status_code == 200 || response.status_code == 201) {
				await this.initCommentUser()
				$('#textKomentar').val('');
				document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
			} else {
				swal.fire({
					title: 'Error',
					text: response.message,
					icon: 'error',
					showConfirmButton: true,
				});
				document.getElementById("btnSendComment").innerHTML = `<i class="fas fa-comment"></i> Kirim`;
			}
			e.preventDefault();
		}
		document.getElementById("form-comment").addEventListener("submit", eventSendCommentar)
	},

	async _downloadKTAN() {
		
		const eventDownloadKTAN = async (e) => {
			
			
			e.preventDefault();
			// let area = document.getElementById('cetakKTAN').innerHTML;
			// let name = document.getElementById('textNamaLengkap').value;
			// let ktan = document.getElementById('textKTAN').value;
			// let data = await ApiAnggota.getPrivate()
			// let pasFoto = data.data.avatar;
			// let expired = 'Seumur Hidup'
			// if (data.status_code != 200) {
			// 	NotificationModal.show('Gagal memuat data', 'error')
			// }else {
			// 	let a = window.open('','', `height=500`, 'width=500', `top=0`, `left=0`)
			// 	let content = `
			// 	<!DOCTYPE html>
			// 	<html lang="en">
			// 	<head>
			// 		<meta charset="UTF-8">
			// 		<meta http-equiv="X-UA-Compatible" content="IE=edge">
			// 		<meta name="viewport" content="width=device-width, initial-scale=1.0">
			// 		<title>KTAN DIGITAL</title>
			// 		<style>
			// 			* {
			// 				font-family: Arial, Helvetica, sans-serif;
			// 			}
			// 			body {
			// 				margin: 0;
			// 				width: 8.56cm;
			// 				height: 5.398cm;
			// 				border-radius: 10px;
			// 			}
			// 			@media print{
			// 				body {
			// 					margin: 0;
			// 					width: 8.56cm;
			// 					height: 5.398cm;
			// 					border-radius: 10px;
			// 				}
			// 			}
			// 			h3,h4,h5 {
			// 				margin: 0;
			// 				padding: 0;
			// 				text-align: center;
			// 			}
			// 			.container {
			// 				background: url('images/desain-KTAN.jpg');
			// 				background-position: center;
			// 				background-size: 100%;
			// 				background-repeat: no-repeat;
			// 				border-radius: 10px;
			// 			}
			// 			.container .body {
			// 				width: 8.56cm;
			// 				height: 5.398cm;
		
			// 			}
			// 		</style>
			// 	</head>
			// 	<body>
			// 		<div class="container">
			// 			<div class="body">
			// 				<table style="width: 100%; height: 100%;">
			// 					<tr>
			// 						<td height="" align="center" style="padding-top: 20px;">
			// 							<strong style="font-size: 10px;">${name}</strong>
			// 							<H5 style=" margin-top: 8px;">${ktan}</H5>
			// 						</td>
			// 						<td width="30%" align="center" style="padding-right: 5px;">
			// 							<img loading="lazy" style="width: 1.64cm; height: 2.2cm; padding-top: 50px; padding-left: 2px;" src="${pasFoto}" alt="Foto Anggota">
			// 							<img loading="lazy" style="width: 0.8cm; height: 0.8cm; padding-left: 2px;" src="images/qrcode.png" alt="qrcode">
			// 						</td>
			// 					</tr>
			// 					<tr>
			// 						<td style="font-size: 10px; padding-left: 10px;">
			// 							Expired Date : ${expired}
			// 						</td>
			// 					</tr>
			// 				</table>
			// 			</div>
			// 		</div>
					
			// 	</body>
			// 	</html>
			// 	`
			// 	a.document.write(content)
			// 	a.document.close();
			// 	a.print();
			// }
			
			document.getElementById("btnCetakKTAN").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			const response = await ApiAnggota.downloadKTAN();

			if (response.status_code != 200) {
				document.getElementById("btnCetakKTAN").innerHTML = `<i class="fas fa-print"></i> Cetak KTAN`;
			} else {
				document.getElementById("btnCetakKTAN").innerHTML = `<i class="fas fa-print"></i> Cetak KTAN`;
			}


			e.preventDefault();
		}
		document.getElementById('btnCetakKTAN').addEventListener('click', eventDownloadKTAN);
	}





}

export default OverviewInitiator;
