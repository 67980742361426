import StatistikIuranPCPPPresenter from '../../../presenter/menu_pengurus_pp/statistik-iuran-pc-pp-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';


const StatisticPCPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
       
        <div class="row">
            <div class="col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
                        <i class="fas fa-chart-bar"></i> STATISTIK PENDAPATAN IURAN TIAP PD
                    </div>
                    <div class="card-body">
                        <div class="row mb-3 d-none">
                            <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Dari Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateDariTanggal"></input>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateSampaiTanggal"></input>
                                    </div>
                                    <div class="col-sm-12 text-right">
                                        <button class="btn btn-primary mt-4" id="btnHitungData"><i class="fas fa-calculator"></i> Hitung Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card mb-3">
                                    <div class="card-header text-primary font-weight-bold">
                                        Filter Data
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-xl-12 col-lg-12">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <label class="control-label font-weight-bold">PD</label>
                                                        <select id="selectPD" class="form-control">
                                                            <option value="">Pilih Salah Satu</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableListIuranPD" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>KODE PC</th>
                                                <th>NAMA PC</th>
                                                <th>PENDAPATAN IURAN</th>
                                                <th>PENDAPATAN PD (30%)</th>
                                                <th>KEWAJIBAN SETOR KE PP (10%)</th>
                                                <th>TELAH DISETORKAN</th>
                                                <th>SISA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
            
        </div>

    
        
        `;
		return view;
	  },

	async afterRender() {
		await StatistikIuranPCPPPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default StatisticPCPD;