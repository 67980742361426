import StatistikIuranPDPresenter from '../../../presenter/menu_pengurus_pd/statistik-iuran-pd-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';





const StatistikPD = {
	async render() {
		const view = `
		<!-- DataTales Example -->
       
        <div class="row">
            <div class="col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
                        <i class="fas fa-chart-bar"></i> STATISTIK PENDAPATAN IURAN TIAP PC
                    </div>
                    <div class="card-body">
                        <div class="row mb-3 d-none">
                            <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Dari Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateDariTanggal"></input>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateSampaiTanggal"></input>
                                    </div>
                                    <div class="col-sm-12 text-right">
                                        <button class="btn btn-primary mt-4" id="btnHitungData"><i class="fas fa-calculator"></i> Hitung Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="alert alert-success align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-check"></i> PENDAPATAN IURAN SEMUA PC<hr>
                                            <h1 class="font-weight-bold" id="iuran"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-success align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-sync-alt"></i> ESTIMASI PENDAPATAN PD (30%)<hr>
                                            <h1 class="font-weight-bold" id="forPD"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-info align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-sync-alt"></i> ESTIMASI PENDAPATAN PP (10%)<hr>
                                            <h1 class="font-weight-bold" id="forPP"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-info align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-sync-alt"></i> ESTIMASI TOTAL SETORAN (40%)<hr>
                                            <h1 class="font-weight-bold" id="forAll"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-success align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-check"></i> SETORAN MASUK (DARI PC KE PD)<hr>
                                            <h1 class="font-weight-bold" id="depositPaid"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-danger align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-times"></i> TOTAL IURAN YANG BELUM DISETORKAN<hr>
                                            <h1 class="font-weight-bold" id="totalSisaIuran"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-info align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-share-alt"></i> SISA ESTIMASI PENDAPATAN PD (30%)<hr>
                                            <h1 class="font-weight-bold" id="forPDSisa"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-info align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-share-alt"></i> SISA ESTIMASI PENDAPATAN PP (10%)<hr>
                                            <h1 class="font-weight-bold" id="forPPSisa"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="alert alert-danger align-items-center justify-content-between text-right font-weight-bold">
                                            <i class="fas fa-times"></i> SISA SETORAN (DARI PC KE PD)<hr>
                                            <h1 class="font-weight-bold" id="depositSisa"><div class="shimmer title-line"></div></h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableListIuranPC" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>KODE PC</th>
                                                <th>NAMA PC</th>
                                                <th>PENDAPATAN IURAN</th>
                                                <th>KEWAJIBAN SETOR KE PD(30%)</th>
                                                <th>KEWAJIBAN SETOR KE PP(10%)</th>
                                                <th>TOTAL KEWAJIBAN SETOR(40%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
                        <i class="fas fa-check"></i> APPROVEMENT SHARING IURAN PC
                    </div>
                    <div class="card-body">
                        <div class="card mb-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-xl-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status</label>
                                                <select id="selectStatusIn" class="form-control">
                                                    <option value="confirm">Menunggu Konfirmasi</option>
                                                    <option value="approve">Lunas</option>
                                                    <option value="pending">Belum Bayar</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">PC</label>
                                                <select id="selectPCIn" class="form-control">
                                                    <option value="">Pilih Salah Satu</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            <button  type="button" class="btn btn-outline-primary" id="unduh_statistic">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="20 20 20 20">
									<path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
									<path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
								</svg><i class="fas fa-file-excel"></i> Download File Excel</button>
                                
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableSetorPC" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>TANGGAL SETOR</th>
                                                <th>NAMA PC</th>
                                                <th>JUMLAH SETOR</th>
                                                <th>METODE PEMBAYARAN</th>
                                                <th>CATATAN</th>
                                                <th>BUKTI BAYAR SETOR</th>
                                                <th>STATUS</th>
                                                <th>AKSI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>

    
    <div class="modal fade" id="commentModalReject" tabindex="-1" role="dialog" aria-labelledby="commentModal"
    aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-primary font-weight-bold">
                    Revisi & Berikan Catatan
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <form id="form-comment">
                                <label class="control-label font-weight-bold">Komentar</label>
                                <textarea class="form-control" id="komentarPengajuanReject" name="komentar" placeholder="Komentar..." required></textarea>
                                <br>
                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanReject"><i class='fas fa-comment'></i> Revisi & Berikan Catatan</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="commentModalSetor" tabindex="-1" role="dialog" aria-labelledby="commentModal"
    aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-primary font-weight-bold">
                    Noted Sharing
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalBayarIuran" tabindex="-1" role="dialog" aria-labelledby="modalBayarIuran" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary">Upload Bukti Bayar</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="ttd">
                    <div class="alert alert-info">
                        <h5 class="font-weight-bold">Informasi Rekening :</h5>
                        <div id="informasiRekening2">

                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label font-weight-bold col-sm-12">Tanggal Setor</label>
                        <div class="col-sm-12">
                            <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateTanggalSetor">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label font-weight-bold col-sm-12">Metode Pembayaran</label>
                        <div class="col-sm-12">
                            <select class="form-control" id="selectPaymentMethod">
                                <option value="">Pilih Salah Satu</option>
                                <option value="cash">Cash</option>
                                <option value="bank_transfer">Bank Transfer</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label font-weight-bold col-sm-12">Upload Bukti Bayar (max 500KB): </label>
                        <div id="form-upload-bukti-bayar-iuran" class="col-sm-12 dropzone">
                            <input type="hidden" name="file" style=""/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="btnSaveIuran">Kirim Bukti Bayar</button>
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
	<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>

		

        
        `;
		return view;
	  },

	async afterRender() {
		await StatistikIuranPDPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default StatistikPD;