/* eslint-disable new-cap */
import CONFIG from '../config/globals/config.js';
import API_ENDPOINT from '../config/globals/endpoint.js';
import Autentication from '../utils/autentication.js';
import API_ENDPOINT_ANGGOTA from "../config/globals/menu_anggota_endpoint.js";
import NetworkHelper from '../utils/network-helper.js';
import fetchWithTimeout from '../utils/fetch-helper.js';
class ApiAnggota {


	static async checkMembership() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.CHECK_MEMBERSHIP, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async getPrivate() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.GET_DATA_ANGGOTA, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				
				if (responseJson.status_code == 200) {
					await Autentication.savePCID(responseJson.data.user_pc_aktif_id)
					await Autentication.savePDID(responseJson.data.user_pd_aktif_id)
					await Autentication.savePCMember(responseJson.data.member_pc)
					await Autentication.savePDMember(responseJson.data.member_pd)
					mixpanel.identify(responseJson.data.uuid)
 
					 mixpanel.people.set({ '$name': `${responseJson.data.name}`,
                      '$email': `${responseJson.data.email}`,
                      '$ktan' : `${responseJson.data.ktan}`,
					  '$phone number' : `${responseJson.data.phone_number}`
                      });
				}
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async checkIsPcPdLocked() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.GET_DATA_ANGGOTA_CHECK, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async getInvoice() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.GET_INVOICE, {
				method: 'GET',
				headers: myHeaders
		})
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendPengajuanKTAN({
		type
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"jenis_pengajuan": "membership",
			"type_pengajuan": type,
  			"keterangan": "Pengajuan keanggotaan baru"
		  });
		

		try {
		const fetchApi = await fetchWithTimeout(API_ENDPOINT.SEND_PENGAJUAN_KTAN, {
			method: 'POST',
			headers: myHeaders,
			body: rawValue
		})
		const responseJson = await fetchApi.json();
		if (await NetworkHelper.isResponseAccepted(responseJson)) {
			return responseJson;
		} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async requestInvoice({
		bulanAwal,
		bulanAkhir,
		nominalIuran,
		jumlahBulan,
		totalIuran
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"bulan_awal": bulanAwal,
			"bulan_akhir": bulanAkhir,
			"nominal_iuran": nominalIuran,
			"jumlah_bulan": jumlahBulan,
			"total_iuran": totalIuran
		  });
		

		try {
		const fetchApi = await fetchWithTimeout(API_ENDPOINT.REQUEST_INVOICE, {
			method: 'POST',
			headers: myHeaders,
			body: rawValue
		})
		const responseJson = await fetchApi.json();
		if (await NetworkHelper.isResponseAccepted(responseJson)) {
			return responseJson;
		} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updateDataPrivate({
		name,
		jenis_kelamin,
		tempat_lahir,
		tanggal_lahir,
		phone_number,
		address,
		village_id,
		sub_regency_id,
		regency_id,
		province_id
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"name": `${name}`,
			"jenis_kelamin": `${jenis_kelamin}`,
			"tempat_lahir": `${tempat_lahir}`,
			"tanggal_lahir": `${tanggal_lahir}`,
			"phone_number": `${phone_number}`,
			"address": `${address}`,
			"village_code": `${village_id}`,
			"district_code": `${sub_regency_id}`,
			"city_code": `${regency_id}`,
			"province_code": `${province_id}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_DATA_ANGGOTA, {
				method: 'PUT',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updatePassword({
		currentPassword,
		newPassword,
		passwordConfirmation
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"current_password": `${currentPassword}`,
			"password": `${newPassword}`,
			"password_confirmation": `${passwordConfirmation}`,
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_PASSWORD, {
				method: 'PUT',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updateDataDomicille({
		address,
		village_id,
		sub_regency_id,
		regency_id,
		province_id
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var rawValue = JSON.stringify({
			"address": `${address}`,
			"village_code": `${village_id}`,
			"district_code": `${sub_regency_id}`,
			"city_code": `${regency_id}`,
			"province_code": `${province_id}`
		  });

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_DATA_DOMICILE, {
				method: 'PUT',
				headers: myHeaders,
				body: rawValue
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createPendidikan({
		jenjang,
		nama_institusi_pendidikan,
		tahun_lulus,
		nomor_ijazah,
		tanggal_ijazah,
		jenis_dokumen,
		imageIjazah
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("jenjang", jenjang);
		formdata.append("nama_institusi_pendidikan", nama_institusi_pendidikan);
		formdata.append("tahun_lulus", tahun_lulus);
		formdata.append("nomor_ijazah", nomor_ijazah);
		formdata.append("tanggal_ijazah", tanggal_ijazah);
		formdata.append("jenis_dokumen", jenis_dokumen);
		formdata.append("pendidikan_dokumen", imageIjazah);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_PENDIDIKAN, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createPembayaranIuran({
		buktiBayar
	}) {
		
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			myHeaders.append('Accept', 'application/json');
			myHeaders.append('Access-Control-Allow-Origin', '{*}');
			myHeaders.append('Access-Control-Allow-Credentials', 'true');
			myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
			
			var formdata = new FormData();
			formdata.append("_method", "PUT");
			formdata.append("bukti_bayar_membership", buktiBayar);

			try {
				const fetchApi = await fetchWithTimeout(API_ENDPOINT.GET_INVOICE, {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				})
				const responseJson = await fetchApi.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			} catch (err) {
				console.log(err)
				return {};
			}
		
		
	}
	
	static async createPekerjaan({
		nama_tempat_kerja,
		alamat_tempat_kerja,
		telp_fax,
		phone_number,
		email,
		jabatan,
		jenis_tempat_kerja,
		dari_tahun,
		sampai_tahun,
		pekerjaan_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
		var formdata = new FormData();
		formdata.append("nama_tempat_kerja", nama_tempat_kerja);
		formdata.append("alamat_tempat_kerja", alamat_tempat_kerja);
		formdata.append("telp_fax", telp_fax);
		formdata.append("phone_number", phone_number);
		formdata.append("email", email);
		formdata.append("jabatan", jabatan);
		formdata.append("jenis_tempat_kerja", jenis_tempat_kerja);
		formdata.append("dari_tahun", dari_tahun);
		formdata.append("sampai_tahun", sampai_tahun);
		formdata.append("pekerjaan_dokumen", pekerjaan_dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_PEKERJAAN, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async createOrganisasi({
		nama_organisasi,
		alamat_organisasi,
		jabatan,
		dari_tahun,
		sampai_tahun,
		organisasi_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
		var formdata = new FormData();
		formdata.append("nama_organisasi", nama_organisasi);
		formdata.append("alamat_organisasi", alamat_organisasi);
		formdata.append("jabatan", jabatan);
		formdata.append("dari_tahun", dari_tahun);
		formdata.append("sampai_tahun", sampai_tahun);
		formdata.append("organisasi_dokumen", organisasi_dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_ORGANISASI, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createSTRTTK({
		nomor_strttk,
		tanggal,
		berlaku_sampai,
		strttk_dokumen
		
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
		var formdata = new FormData();
		formdata.append("nomor_strttk", nomor_strttk);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		formdata.append("strttk_dokumen", strttk_dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_STRTTK, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async createSERKOM({
		nomor_serkom,
		tanggal,
		berlaku_sampai,
		serkom_dokumen
		
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
		var formdata = new FormData();
		formdata.append("nomor_serkom", nomor_serkom);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		formdata.append("serkom_dokumen", serkom_dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_SERKOM, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}


	static async createSIPTTK({
		nomor_strttk,
		nomor_sipttk,
		jenis_sipttk,
		tempat_kerja_id,
		tanggal,
		berlaku_sampai,
		nama_apoteker_supervisi,
		nomor_stra_apoteker,
		sipttk_dokumen
		
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		
		var formdata = new FormData();
		formdata.append("nomor_strttk", nomor_strttk);
		formdata.append("nomor_sipttk", nomor_sipttk);
		formdata.append("jenis_sipttk", jenis_sipttk);
		formdata.append("tempat_kerja_id", tempat_kerja_id);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		formdata.append("nama_apoteker_supervisi", nama_apoteker_supervisi);
		formdata.append("nomor_stra_apoteker", nomor_stra_apoteker);
		formdata.append("sipttk_dokumen", sipttk_dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CREATE_SIPTTK, {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	

	static async updatePendidikan({
		id,
		jenjang,
		nama_institusi_pendidikan,
		tahun_lulus,
		nomor_ijazah,
		tanggal_ijazah,
		jenis_dokumen,
		imageIjazah
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("jenjang", jenjang);
		formdata.append("nama_institusi_pendidikan", nama_institusi_pendidikan);
		formdata.append("tahun_lulus", tahun_lulus);
		formdata.append("nomor_ijazah", nomor_ijazah);
		formdata.append("tanggal_ijazah", tanggal_ijazah);
		formdata.append("jenis_dokumen", jenis_dokumen);
		if (imageIjazah != null) {
			formdata.append("pendidikan_dokumen", imageIjazah);
		}


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_PENDIDIKAN(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async sendBuktiBayar({
		buktiBayar,
		invoiceID
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		if (buktiBayar != null) {
			formdata.append("bukti_bayar_membership", buktiBayar);
			formdata.append("membership_fee_id", invoiceID);
		}


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.SEND_IURAN, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updatePekerjaan({
		id,
		nama_tempat_kerja,
		alamat_tempat_kerja,
		telp_fax,
		phone_number,
		email,
		jabatan,
		jenis_tempat_kerja,
		dari_tahun,
		sampai_tahun,
		pekerjaan_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("nama_tempat_kerja", nama_tempat_kerja);
		formdata.append("alamat_tempat_kerja", alamat_tempat_kerja);
		formdata.append("telp_fax", telp_fax);
		formdata.append("phone_number", phone_number);
		formdata.append("email", email);
		formdata.append("jabatan", jabatan);
		formdata.append("jenis_tempat_kerja", jenis_tempat_kerja);
		formdata.append("dari_tahun", dari_tahun);
		formdata.append("sampai_tahun", sampai_tahun);
		if (pekerjaan_dokumen != null) {
		formdata.append("pekerjaan_dokumen", pekerjaan_dokumen);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_PEKERJAAN(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updateOrganisasi({
		id,
		nama_organisasi,
		alamat_organisasi,
		jabatan,
		dari_tahun,
		sampai_tahun,
		organisasi_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("nama_organisasi", nama_organisasi);
		formdata.append("alamat_organisasi", alamat_organisasi);
		formdata.append("jabatan", jabatan);
		formdata.append("dari_tahun", dari_tahun);
		formdata.append("sampai_tahun", sampai_tahun);
		if (organisasi_dokumen != null) {
			formdata.append("organisasi_dokumen", organisasi_dokumen);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_ORGANISASI(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async updateSTRTTK({
		id,
		nomor_strttk,
		tanggal,
		berlaku_sampai,
		strttk_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("nomor_strttk", nomor_strttk);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		if (strttk_dokumen != null) {
			formdata.append("strttk_dokumen", strttk_dokumen);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_STRTTK(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async updateSERKOM({
		id,
		nomor_serkom,
		tanggal,
		berlaku_sampai,
		serkom_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("nomor_serkom", nomor_serkom);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		if (serkom_dokumen != null) {
			formdata.append("serkom_dokumen", serkom_dokumen);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_SERKOM(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async updateSIPTTK({
		id,
		nomor_strttk,
		nomor_sipttk,
		jenis_sipttk,
		tempat_kerja_id,
		tanggal,
		berlaku_sampai,
		nama_apoteker_supervisi,
		nomor_stra_apoteker,
		sipttk_dokumen
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("nomor_strttk", nomor_strttk);
		formdata.append("nomor_sipttk", nomor_sipttk);
		formdata.append("jenis_sipttk", jenis_sipttk);
		formdata.append("tempat_kerja_id", tempat_kerja_id);
		formdata.append("tanggal", tanggal);
		formdata.append("berlaku_sampai", berlaku_sampai);
		formdata.append("nama_apoteker_supervisi", nama_apoteker_supervisi);
		formdata.append("nomor_stra_apoteker", nomor_stra_apoteker);
		if (sipttk_dokumen != null) {
			formdata.append("sipttk_dokumen", sipttk_dokumen);
		}

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_SIPTTK(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deletePendidikan({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_PENDIDIKAN(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async cencelInvoice({
		invoiceID,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.CANCEL_INVOICE(invoiceID), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deletePekerjaan({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_PEKERJAAN(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deleteOrganisasi({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_ORGANISASI(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deleteSTRTTK({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_STRTTK(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async deleteSERKOM({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_SERKOM(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async deleteSIPTTK({
		id,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT.UPDATE_SIPTTK(id), {
				method: 'DELETE',
				headers: myHeaders
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getPendidikan() {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.CREATE_PENDIDIKAN,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
	static async getPendidikanByID({id}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.GET_PENDIDIKAN_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}
	static async getPekerjaan() {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.CREATE_PEKERJAAN,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}
	static async getPekerjaanById(PekerjaanID) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.UPDATE_PEKERJAAN(PekerjaanID),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
	}
	
	static async getCommentarKTAN(
		lastPage
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.GET_COMMENTAR_KTAN(lastPage),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}
	static async getCommentar(
		pengajuanID,
		commentFor,
		lastPage
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const response = await fetchWithTimeout(API_ENDPOINT.GET_COMMENTAR(pengajuanID,commentFor,lastPage),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async sendCommentar({
		pengajuanID,
		contentComment,
		commentFor,
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var rawValue = JSON.stringify({
			"comment_for": `${commentFor}`,
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT.CREATE_COMMENTAR(pengajuanID),{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	
	static async sendCommentarKTAN(
		contentComment,
		commentFor,
	) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var rawValue = JSON.stringify({
			"body": `${contentComment}`
		  });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT.CREATE_COMMENTAR_KTAN(commentFor),{
				method: 'POST',
				headers: myHeaders,
				body: rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	
	static async createSKP({
		tanggal_mulai,
		tanggal_selesai,
		nama_kegiatan,
		nomor_sertifikat,
		kategori,
		jenis_kegiatan,
		nomor_surat_keputusan,
		tempat_penyelenggaraan,
		kontribusi_peserta,
		berlaku_sampai,
		skp,
		ketua_kegiatan,
		informasi,
		catatan,
		sertifikatSKP,
		buktiKegiatan
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("tanggal_mulai", tanggal_mulai);
		formdata.append("tanggal_selesai", tanggal_selesai);
		formdata.append("nama_kegiatan", nama_kegiatan);
		formdata.append("nomor_sertifikat", nomor_sertifikat);
		formdata.append("kategori", kategori);
		formdata.append("jenis_kegiatan", jenis_kegiatan);
		formdata.append("nomor_surat_keputusan", nomor_surat_keputusan);
		formdata.append("tempat_penyelenggaraan", tempat_penyelenggaraan);
		formdata.append("kontribusi_peserta", kontribusi_peserta);
		formdata.append("berlaku_sampai", berlaku_sampai);
		formdata.append("skp", skp);
		formdata.append("ketua_kegiatan", ketua_kegiatan);
		formdata.append("informasi", informasi);
		formdata.append("catatan", catatan);
		if (sertifikatSKP != null  ) {
			formdata.append("sertifikat_skp", sertifikatSKP);
		}
		if (buktiKegiatan != null) {
			formdata.append("bukti_kegiatan", buktiKegiatan);	
		}


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.CREATE_SKP, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async updateSKP({
		id,
		tanggal_mulai,
		tanggal_selesai,
		nama_kegiatan,
		nomor_sertifikat,
		kategori,
		jenis_kegiatan,
		nomor_surat_keputusan,
		tempat_penyelenggaraan,
		kontribusi_peserta,
		skp,
		ketua_kegiatan,
		informasi,
		catatan,
		sertifikatSKP,
		buktiKegiatan
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("tanggal_mulai", tanggal_mulai);
		formdata.append("tanggal_selesai", tanggal_selesai);
		formdata.append("nama_kegiatan", nama_kegiatan);
		formdata.append("nomor_sertifikat", nomor_sertifikat);
		formdata.append("kategori", kategori);
		formdata.append("jenis_kegiatan", jenis_kegiatan);
		formdata.append("nomor_surat_keputusan", nomor_surat_keputusan);
		formdata.append("tempat_penyelenggaraan", tempat_penyelenggaraan);
		formdata.append("kontribusi_peserta", kontribusi_peserta);
		formdata.append("skp", skp);
		formdata.append("ketua_kegiatan", ketua_kegiatan);
		formdata.append("informasi", informasi);
		formdata.append("catatan", catatan);
		if (sertifikatSKP != null) {
			formdata.append("sertifikat_skp", sertifikatSKP);
		}
		if (buktiKegiatan != null) {
			formdata.append("bukti_kegiatan", buktiKegiatan);
		}


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.SKP_UPDATE(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deleteSKP({
		id
	}) {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.SKP_UPDATE(id), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getSKP() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.CREATE_SKP, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async getCountSKP() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.COUNT_SKP, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async getSKPbyID(id) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.SKP_UPDATE(id), {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async getFormulirSerkom() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.FORMULIR_SERKOM, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async getFormulirMutasi() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.FORMULIR_MUTASI, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async getFormulirSTRTTK() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.FORMULIR_STRTTK, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async getFormulirSIPTTK() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT_ANGGOTA.FORMULIR_SIPTTK, {
				method: 'GET',
				headers: myHeaders
		})
		
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async uploadBerkasSerkom({
		id,
		document_name,
		dokumen
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", document_name);
		formdata.append("document", dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.UPDATE_SERKOM_BERKAS(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async uploadBerkasSTRTTK({
		id,
		document_name,
		dokumen
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", document_name);
		formdata.append("document", dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.UPDATE_STRTTK_BERKAS(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async uploadBerkasSIPTTK({
		id,
		document_name,
		dokumen
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", document_name);
		formdata.append("document", dokumen);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.UPDATE_SIPTTK_BERKAS(id), {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendPengajuanSerkom({
		pekerjaan_id,
		pendidikan_id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var raw = JSON.stringify({
			"pekerjaan_id": pekerjaan_id,
			"pendidikan_id": pendidikan_id
		  });


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SERKOM, {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async getPengajuanSerkomByID({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SERKOM_BY_ID(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async getPengajuanSTRTTKByID({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_STRTTK_BY_ID(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async getPengajuanSIPTTKByID({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SIPTTK_BY_ID(id), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async getPersyaratanSIPTTKByPCID({
		regencySaranaID
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.GET_PERSYARATAN_SIPTTK_BY_PC_ID(regencySaranaID), {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async deletePengajuanSerkom({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SERKOM_BY_ID(id), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async getInformationSTRTTK(){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.INFORMATION_STRTTK, {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	
	static async getInformationSIPTTK(){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.INFORMATION_SIPTTK, {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async sendPengajuanSTRTTK({
		jenis_permohonan,
		perpanjang_dengan,
		nomor_strttk,
		pekerjaan_id,
		pendidikan_id,
		surat_pernyataan_patuh_kode_etik,
		surat_pernyataan_sumpah,
		surat_keterangan_praktik,
		surat_pernyataan_keabsahan_dokumen,
		surat_permohonan_pc
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var formdata = new FormData();
		formdata.append("jenis_permohonan", jenis_permohonan);
		formdata.append("perpanjang_dengan", perpanjang_dengan);
		formdata.append("nomor_strttk", nomor_strttk);
		formdata.append("pendidikan_id", pendidikan_id);
		formdata.append("pekerjaan_id", pekerjaan_id);
		formdata.append("surat_pernyataan_patuh_kode_etik", surat_pernyataan_patuh_kode_etik);
		formdata.append("surat_pernyataan_sumpah", surat_pernyataan_sumpah);
		formdata.append("surat_keterangan_praktik", surat_keterangan_praktik);
		formdata.append("surat_pernyataan_keabsahan_dokumen", surat_pernyataan_keabsahan_dokumen);
		formdata.append("surat_permohonan_pc", surat_permohonan_pc);


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_STRTTK, {
				method: 'POST',
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async sendPengajuanSIPTTK({
		jenis_permohonan,
		jenis_rekomendasi,
		kepada,
		di,
		pendidikan_id,
		pekerjaan_id,
		nomor_strttk,
		masa_berlaku,
		province_sarana_id,
		regency_sarana_id,
		telp_sarana,
		email_sarana,
		hari_praktik,
		jam_praktik,
		nama_pimpinan,
		jabatan_pimpinan,
		informasi_fasilitas
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var raw = JSON.stringify({
			jenis_permohonan: jenis_permohonan,
			jenis_rekomendasi: jenis_rekomendasi,
			kepada: kepada,
			di: di,
			pendidikan_id: pendidikan_id,
			pekerjaan_id: pekerjaan_id,
			nomor_strttk: nomor_strttk,
			masa_berlaku_strttk: masa_berlaku,
			province_sarana_id: province_sarana_id,
			regency_sarana_id: regency_sarana_id,
			telp_sarana: telp_sarana,
			email_sarana: email_sarana,
			hari_praktik: hari_praktik,
			jam_praktik: jam_praktik,
			nama_pimpinan: nama_pimpinan,
			jabatan_pimpinan: jabatan_pimpinan,
			informasi_fasilitas: informasi_fasilitas
		  });


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SIPTTK, {
				method: 'POST',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async updatePengajuanSIPTTK({
		idPengajuan,
		jenis_permohonan,
		jenis_rekomendasi,
		kepada,
		di,
		pendidikan_id,
		pekerjaan_id,
		nomor_strttk,
		masa_berlaku,
		province_sarana_id,
		regency_sarana_id,
		telp_sarana,
		email_sarana,
		hari_praktik,
		jam_praktik,
		nama_pimpinan,
		jabatan_pimpinan,
		informasi_fasilitas
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	
		var raw = JSON.stringify({
			jenis_permohonan: jenis_permohonan,
			jenis_rekomendasi: jenis_rekomendasi,
			pendidikan_id: pendidikan_id,
			pekerjaan_id: pekerjaan_id,
			nomor_strttk: nomor_strttk,
			masa_berlaku_strttk: masa_berlaku,
			province_sarana_id: province_sarana_id,
			regency_sarana_id: regency_sarana_id,
			telp_sarana: telp_sarana,
			email_sarana: email_sarana,
			hari_praktik: hari_praktik,
			jam_praktik: jam_praktik,
			nama_pimpinan: nama_pimpinan,
			jabatan_pimpinan: jabatan_pimpinan,
			informasi_fasilitas: informasi_fasilitas
		  });


		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.UPDATE_PENGAJUAN_SIPTTK(idPengajuan), {
				method: 'PUT',
				headers: myHeaders,
				body: raw,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async deletePengajuanSTRTTK({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_STRTTK_BY_ID(id), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async deletePengajuanSIPTTK({
		id
	}){
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
	

		try {
			const fetchApi = await fetchWithTimeout(API_ENDPOINT_ANGGOTA.PENGAJUAN_SIPTTK_BY_ID(id), {
				method: 'DELETE',
				headers: myHeaders,
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async searchPendidikan({
		key
	}){
		
		try {
			const fetchApi = await fetch(API_ENDPOINT_ANGGOTA.PENDIDIKAN_SEARCH(key), {
				method: 'GET',
				redirect: 'follow'
			})
			const responseJson = await fetchApi.json();
			
			return responseJson;
			
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadSTRTTKPD({id}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_STRTTK_PD(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Surat Rekomendasi Pengajuan Registrasi STRTTK.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async download_format_syarat({
		nama_dokumen
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			document_name: nama_dokumen,
		  });
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_FORMAT_SYARAT, {
				method: 'POST',
				headers: myHeaders,
				body: raw
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = nama_dokumen;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			// console.log(err)
			return {};
		}
	}


	
	static async downloadSerkom({id}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_SERKOM(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'SERKOM_'+id;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadSTRTTKPC({id}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_STRTTK_PC(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Surat Rekomendasi Pengajuan Registrasi STRTTK by Cabang.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	static async downloadKTAN() {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_KTAN, {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'Kartu Tanda Anggota Nasional.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async downloadBorangSKP({
		id,
		namaAnggota
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_BORANG_SKP(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = `Borang SKP_${namaAnggota}.pdf`;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}

	
	static async downloadSKSIPTTK({
		id,
		name
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_SK_SIPTTK_PC(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'REKOM_PENERBITAN_SIPTTK_'+name+'.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
	static async downloadSKSIPTTKPencabutan({
		id,
		name
	}) {
		try {
		let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		const response =  await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_SK_SIPTTK_PC_PENCABUTAN(id), {
				method: 'GET',
				headers: myHeaders
		})
		.then(response => response.blob())
		.then(blob => {
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = 'REKOM_PENCABUTAN_SIPTTK_'+name+'.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
      			URL.revokeObjectURL(url); 
			}, 0);
		})
			const responseJson = await response.text();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			console.log(err)
			return {};
		}
	}
}

export default ApiAnggota;
