
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiKepengurusanPP {

	static async getAnggota({
		status_regis,
		status_ktan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.ANGGOTA(status_regis,status_ktan),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	
	static async approveRegisOld (
        data,
    ){
		
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
        var rawValue = JSON.stringify({
            "approval": data.approval,
            "reason": data.reason,
          });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.APPROVE_REGIS_OLD(data.id),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getAnggotaByID(uuid){
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_ANGGOTA_BY_ID(uuid), {
					method: 'GET',
					headers: myHeaders
			})
			
				const responseJson = await response.json();
				if (await NetworkHelper.isResponseAccepted(responseJson)) {
					return responseJson;
				} return {};
			} catch (err) {
				console.log(err)
				return {};
			}
	}

	
	static async restoreAnggota(uuid){
		try {
			let myHeaders =  new Headers();
			let token = await Autentication.getToken()
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Accept', 'application/json');
			myHeaders.append('Access-Control-Allow-Origin', '{*}');
			myHeaders.append('Access-Control-Allow-Credentials', 'true');
			myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
			
			var raw = JSON.stringify({
				"uuid": uuid
			});

			const response =  await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.RESTORE_ANGGOTA, {
					method: 'POST',
					headers: myHeaders,
					body : raw
			})
			
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
			} catch (err) {
				console.log(err)
				return {};
			}
	}

    static async getPeriode (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PERIODE_KEPENGURUSAN,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async getPeriodeByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PERIODE_KEPENGURUSAN_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async createPeriode ({
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PERIODE_KEPENGURUSAN,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    
    static async updatePeriode ({
        id,
		masa_bakti,
		keterangan
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "masa_bakti": masa_bakti,
            "keterangan": keterangan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.UPDATE_PERIODE_KEPENGURUSAN(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateDataAnggota ({
        uuid,
		nik,
		ktan,
		pd,
		pc,
		nama_lengkap,
		no_hp,
		email,
		jenis_kelamin,
		tanggal_lahir,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "user_pd_aktif_id": pd,
			"nik": nik,
			"ktan": ktan,
            "user_pc_aktif_id": pc,
            "name": nama_lengkap,
            "phone_number": no_hp,
            "email": email,
            "jenis_kelamin": jenis_kelamin,
            "tanggal_lahir": tanggal_lahir,
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.UPDATE_ANGGOTA_PP_BY_ID(uuid),{
				method : "PATCH",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

    static async deletePeriode ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.DELETE_PERIODE_KEPENGURUSAN(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	
    static async getPengurus (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PENGURUS,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async getPengurusByID ({
		id
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PENGURUS_BY_ID(id),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
	static async createPengurus ({
		periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.PENGURUS,{
				method : "POST",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async updatePengurus ({
		id,
        periode_id,
		user_id,
		jabatan,
		urut_tampil,
		publish_pendidikan,
		publish_organisasi,
		publish_pekerjaan,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
            "periode_id": periode_id,
			"user_id": user_id,
			"jabatan": jabatan,
			"urut_tampil": urut_tampil,
			"publish_pendidikan": publish_pendidikan,
			"publish_organisasi": publish_organisasi,
			"publish_pekerjaan": publish_pekerjaan
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.UPDATE_PENGURUS(id),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
	
    static async deletePengurus ({
        id,
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.DELETE_PENGURUS(id),{
				method : "DELETE",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }
    static async updateTTD ({
        id,
		dokumen
	}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		var formdata = new FormData();
		formdata.append("_method", "PUT");
		formdata.append("document_name", "ttd");
		formdata.append("document", dokumen);

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.UPDATE_TTD(id),{
				method : "POST",
				headers: myHeaders,
				body: formdata,
				redirect: 'follow'
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
    }

	static async updateSettingBiaya({key, value , description,name}){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');

		var raw = JSON.stringify({
			"value": value,
			"description": description,
			"name": name
		  });
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.UPDATE_GENERAL_SETTING(key),{
				method : "PUT",
				headers: myHeaders,
                body : raw
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			
			return {};
		}
	}

	static async getSettingBiaya(){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		  try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_SETTING_GENERAL,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};

		} catch (err) {
			
			return {};
		}
	}

	static async getSettingBiayaSeminar(){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
		
		  try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_SETTING_BIAYA_SEMINAR,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};

		} catch (err) {
			
			return {};
		}
	}
	static async downloadReportByPendidikan({
		filter, document_type
	}) {
		try {
	
			let myHeaders = new Headers();
			let token = await Autentication.getToken();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);

			
			const raw = JSON.stringify({
				"document_type": document_type
			});
	
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_REPORT_BY_PENDIDIKAN(filter), {
				method: 'POST',
				headers: myHeaders,
				body: raw
			});
	
			if (!response.ok) {
				console.error(`❌ Download gagal: ${response.status} - ${response.statusText}`);
				return { success: false };
			}
	
			console.log("✅ File diterima, memproses unduhan...");
	
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			let extention = ''
			if (document_type == 'pdf') {
				extention = '.pdf'
			}else {
				extention = '.xlsx'
			}
			a.download = 'LAPORAN REKAP ANGGOTA BY '+filter+extention;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
				URL.revokeObjectURL(url);
			}, 0);
	
			console.log("📥 Download selesai.");
			return { success: true };
	
		} catch (err) {
			console.error("❌ Error saat download:", err);
			return { success: false };
		}
	}
	
	
	
}


export default ApiKepengurusanPP;