
import API_ENDPOINT_PENGURUS_PC from "../../config/globals/menu_pengurus_pc_endpoint.js";
import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiKepengurusanPC from "../../api/api_pengurus_pc/kepengurusan-pc.js"
import ApiStats from "../../api/data-statistik.js";
import FormatCurrency from "../../utils/initial-currency.js";
import STATISTIK_ENDPOINT from "../../config/globals/statistik_endpoint.js";
import ApiAnggota from "../../api/data-anggota.js";
import ApiRegister from "../../api/data-register.js";


const StatistikKTANPPPCPresenter = {

	async init() {
		await this._setView();
		await this._getListSetorInFiltered()
		await this._getDataPrivate()
	},
	
	async _getDataPrivate() {

		const dataProvince = await ApiRegister.getProvince()
		if(dataProvince.status_code == 200){
			dataProvince.data.forEach(data => {
				document.getElementById("selectPDIn").add(new Option(data.province_name , data.province_code))
			});	
		}

	},
	

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
	},


	async formatCurrency(amount) {
		// Menggunakan metode toLocaleString dengan pengaturan 'id-ID' (Indonesia) untuk format mata uang
		return amount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
	},
	
	
	async _getListSetorInFiltered(){
		let idDaerah = document.getElementById('selectPDIn').value
		await this._getListIuranPD(idDaerah)

		const eventChangeFiltered = async () => {
			let idDaerah = document.getElementById('selectPDIn').value
			await this._getListIuranPD(idDaerah)
		}

		document.getElementById('selectPDIn').addEventListener('change', eventChangeFiltered)
	},

	async _getListIuranPD(idDaerah){
		let accumulationDeposit = '';
		let table = await $('#tableListIuranPD').DataTable({
			"pageLength": 50,
			"lengthMenu": [50, 100],
			"processing": true,
			"destroy": true,
			dom: 'lBfrtip<"actions">',
			buttons: [
				{
					extend: 'copy',
					text: '<i class="fas fa-copy"></i> Copy to clipboard',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Copy to clipboard"
				},
				{
					extend: 'csv',
					text: '<i class="fas fa-file-csv"></i> Download CSV',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file csv"
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"></i> Download Excel',
					exportOptions: {
						columns: ':visible'
					},
					className: "btn btn-light border-primary",
					titleAttr: "Download file excel"
				}
			],
			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${STATISTIK_ENDPOINT.GET_STATS_PP_KTAN('pc', idDaerah)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'pc_id'},
				{ data: 'cabang_name'},
				{ data: 'total_payment', render: function (data,type, rows) {

					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },
				{ data: 'total_deposit_must', render: function (data,type, rows) {
					let nominal = data;
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					var formattedAmount = formatter.format(nominal);

					return formattedAmount
				} },
				{ data: 'total_deposit_paid', render: function (data,type, rows) {

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },
				{ data: 'total_sisa', render: function (data,type, rows) {

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(data);

					return formattedAmount
				} },

			],
			order: [
				[0, 'asc']
			],
			"columnDefs": [
			{
				"targets": [0],
				"visible": true,
				"searchable": true,
			}
			],
			
			"drawCallback": function( settings ) {
				var api = this.api();
				var json = api.ajax.json();
				var data = json.data
				// StatistikKTANPPPCPresenter._getInfoAllPP(data)
			}
		});
	},


	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	


}



export default StatistikKTANPPPCPresenter;