
import CONFIG from '../../config/globals/config.js';
import API_ENDPOINT_PENGURUS_PP from "../../config/globals/menu_pengurus_pp_endpoint.js";
import API_ENDPOINT from '../../config/globals/endpoint.js';
import Autentication from '../../utils/autentication.js';
import NetworkHelper from '../../utils/network-helper.js';
import fetchWithTimeout from "../../utils/fetch-helper.js";

class ApiRole {
    static async getUserByKTAN (
        ktan,
    ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_USER_BY_KTAN(ktan),{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

    static async getAllRole (){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    
		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.GET_All_ROLE,{
				method : "GET",
				headers: myHeaders
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }


    static async assignRole (
        userId ,
        roleId
    ){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    	
        var rawValue = JSON.stringify({
            "user_id": userId,
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.ASSIGN_ROLE(roleId),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	static async removeRole ({
        idUser,
		idRole
    }){
        let myHeaders =  new Headers();
		let token = await Autentication.getToken()
		myHeaders.append('Authorization', `Bearer ${token.replace('"','')}`);
		myHeaders.append('Content-Type', 'application/json');
		myHeaders.append('Accept', 'application/json');
		myHeaders.append('Access-Control-Allow-Origin', '{*}');
		myHeaders.append('Access-Control-Allow-Credentials', 'true');
		myHeaders.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    	
        var rawValue = JSON.stringify({
            "user_uuid": idUser,
          });

		try {
			const response = await fetchWithTimeout(API_ENDPOINT_PENGURUS_PP.REMOVE_ROLE(idRole),{
				method : "POST",
				headers: myHeaders,
                body : rawValue
			});
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			} return {};
		} catch (err) {
			return {};
		}
    }

	
	static async downloadSuratMandat({
		jenisSurat,
		nomorSurat,
		tanggalSurat,
		nian,
		adminType
	}) {
		try {
			let myHeaders = new Headers();
			let token = await Autentication.getToken();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append('Authorization', `Bearer ${token.replace(/"/g, '')}`); // Mengganti semua tanda kutip ganda
			const raw = JSON.stringify({
				"admin_type": adminType,
				"request_type": jenisSurat,
				"nomor_urut_surat": nomorSurat,
				"tanggal_surat": tanggalSurat,
				"nian": nian,
			});
			const response = await fetchWithTimeout(API_ENDPOINT.DOWNLOAD_SURAT_MANDAT, {
				method: "POST",
				headers: myHeaders,
				body: raw
			});
			if (!response.ok) {
				console.error('Network response was not ok:', response.statusText);
				return;
			}
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = url;
			const contentDisposition = response.headers.get('Content-Disposition');
			let filename = 'SURAT MANDAT_'+jenisSurat+'_'+nian+'.pdf';
			if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
				const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
				if (matches != null && matches[1]) { 
					filename = matches[1].replace(/['"]/g, '');
				}
			}
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
			
			const responseJson = await response.json();
			if (await NetworkHelper.isResponseAccepted(responseJson)) {
				return responseJson;
			}

			return {};
	
		} catch (err) {
			console.error('Error:', err);
		}
	}
}


export default ApiRole;
