import NotificationModal from "../../utils/initial_notification.js";
import CONFIG from "../../config/globals/config.js";
import ApiStats from "../../api/data-statistik.js";
import STATISTIK_ENDPOINT from "../../config/globals/statistik_endpoint.js";


const SharingKTANPDPresenter = {

	async init() {
		await this._setView();
		await this._getInfoIuran();
		await this._getStatsIuran('','');
		await this._requestInvoice();
		await this._getListSetorByFiltered();
		await this._sendBuktiBayarBeforeClick();
		// // await this._sendBuktiBayar();
		await this._cancelInvoiceIuran();
		await this._initCommentIuran();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";

		await this._formatNumber('numberJumlahBayar')
		// let responseLocked = await ApiAnggota.checkIsPcPdLocked();
		// if (responseLocked.status_code == 200) {
		// 	if (responseLocked.data.is_pc_locked) {
		// 		window.location.hash = '#/404?reason=iuranpc'
		// 	}
		// }
	},

	async _sendBuktiBayar(idInvoice){
		let DzoneIuran = new Dropzone("#form-upload-bukti-bayar-iuran", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-bukti-bayar-iuran .dz-preview:hidden').remove()
					$('#form-upload-bukti-bayar-iuran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-bukti-bayar-iuran").classList.remove('dz-started');
				});
			}
		});



		const eventCreatPembayaran = async (e) => {
			e.preventDefault();
			if (DzoneIuran.files.length <= 0) {
				NotificationModal.show('Bukti bayar tidak boleh kosong !', 'error')
				document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;
			}else {
				document.getElementById("btnSaveIuran").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPembayaranIuran = await ApiStats.sendBuktiBayarKTAN({
				invoiceID : idInvoice,
				buktiBayar : (DzoneIuran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneIuran.files[0].dataURL ,DzoneIuran.files[0].name ),
				tanggalSetor: document.getElementById("dateTanggalSetor").value,
				paymentMethod: document.getElementById("selectPaymentMethod").value
				});
				if (createPembayaranIuran.status_code != 200) {
					NotificationModal.show(`${createPembayaranIuran.message}`, 'error');
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;				
				} else {
					swal.fire({
						title: 'Success',
						html: "Bukti bayar iuran berhasil di kirim",
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ok'
					}).then(async (result) => {
						if (result.value) {
							$("#getListSetor").DataTable().ajax.reload();
							$("#modalBayarIuran").modal("hide");
						};
					});
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran")	.innerHTML = `Kirim Bukti Bayar`;
				}
				

				e.preventDefault();

			}
		}
		document.getElementById('btnSaveIuran').addEventListener('click', eventCreatPembayaran);

		
		
		
	},

	async _sendBuktiBayarBeforeClick(){
		let idInvoice = '';
		$("#getListSetor tbody").on("click", "#btnBayar", async function () {
			const table = $("#getListSetor").DataTable();
			let data = table.row($(this).parents('tr')).data();
			idInvoice = data.id;
			SharingKTANPDPresenter._sendBuktiBayar(idInvoice)

		})
	},


	
	async _formatNumber(elm){
		// Dapatkan elemen input
		const input = document.getElementById(elm);
		// const input = document.getElementById('textBiayaPenerbitanSKP');

		// Tambahkan event listener untuk memantau perubahan pada input
		input.addEventListener('input', function () {
		// Dapatkan nilai input
			let value = input.value;

			// Hapus semua karakter non-angka dari nilai input
			value = value.replace(/[^0-9]/g, '');

			// Gunakan fungsi Number untuk mengubah nilai menjadi angka
			const numberValue = Number(value);

			// Pastikan nilai yang diubah bukan NaN (Not-a-Number)
			if (!isNaN(numberValue)) {
				// Format nilai sebagai angka dengan tanda koma (contoh: 1,234)
				input.value = numberValue.toLocaleString('id-ID');
			} else {
				// Jika nilai tidak valid, kosongkan input
				input.value = '';
			}

		});

	},

	async _getInfoIuran(){
		const getInfo = await ApiStats.getInfoDepositIuranPC({startDate:'',endDate:''})
		if (getInfo.status_code == 200) {
			let data = getInfo.data;
			$("#infoRekening").html(data.rekening_pd);
			$("#informasiRekening2").html(data.rekening_pd);
		}
	},


	async _getStatsIuran(startDate,endDate){
		const getStats = await ApiStats.getStatsKTAN({
				role : "pd"
			})
		let data = getStats.data;
		if (getStats.status_code == 200) {
			let totalPayment = data.total_payment;
			let depositMust = data.for_pp.total_deposit_must;
			let totalSisa = data.for_pp.total_sisa;
			let totalPaid = data.for_pp.total_deposit_paid;
			
			
			const totalPaymentFormated = totalPayment.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});

			// Format forPD and forPP in Indonesian style
			const depositMustFormated = depositMust.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});

			const totalIuranFormated = totalIuran.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});


			const totalSisaFormated = totalSisa.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});

			const totalPaidFormated = totalPaid.toLocaleString('id-ID',{
				style: 'currency',
				currency: 'IDR'
			});
			
			$("#totalIuran").html(totalPaymentFormated)
			$("#depositMust").html(depositMustFormated)
			$("#deposit_paid").html(totalIuranFormated);
			$("#deposit_paid").html(totalPaidFormated);
			$("#sisa").html(totalSisaFormated);
		}
	},
	
	async _getListSetorByFiltered(){
		let startDate = document.getElementById('dateDariTanggalList').value
		let endDate = document.getElementById('dateSampaiTanggalList').value
		let status = document.getElementById('selectByStatus').value
		let role = "pd"
		let type = '/out';
		await this._getListSetor(role,startDate,endDate,status,type);

		const eventChangeFiltered = async () => {
			let role = "pd"
			let startDate = document.getElementById('dateDariTanggalList').value
			let endDate = document.getElementById('dateSampaiTanggalList').value
			let status = document.getElementById('selectByStatus').value
			let type = '/out';
			const currentDate = await this.today();
			if (endDate == '' || endDate == null) {
				endDate = document.getElementById('dateSampaiTanggalList').value = currentDate
			}
			await this._getListSetor(role,startDate,endDate,status,type);
		}


		document.getElementById('dateDariTanggalList').addEventListener('change', eventChangeFiltered)
		document.getElementById('dateSampaiTanggalList').addEventListener('change', eventChangeFiltered)
		document.getElementById('selectByStatus').addEventListener('change', eventChangeFiltered)
	},
	
	async today() {
		const date = new Date();
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Tambahkan 1 karena bulan dimulai dari 0
		const day = String(date.getDate()).padStart(2, '0');
	  
		return `${year}-${month}-${day}`;
	},
	async _getListSetor(role,startDate,endDate,status,type){
		var contentStatus = ''
		var bgStatus = ''
		if (status == 'approve') {
			contentStatus = 'Approve'
			bgStatus = 'success'
		}else if (status == 'confirm') {
			contentStatus = 'Menunggu Konfirmasi'
			bgStatus = 'info'
		}else {
			contentStatus = 'pending'
			bgStatus = 'warning'
		}
		let table = await $('#getListSetor').DataTable({
			"pageLength": 10,
			"lengthMenu": [10, 25, 50, 100],
			"processing": true,
			"destroy": true,

			'language': {
				'loadingRecords': '&nbsp;',
				'processing': '<div class="spinner"></div>'
			},
			"serverSide": true,
			"searchDelay": 1350,
			"ajax": {
				"url": `${STATISTIK_ENDPOINT.GET_LIST_SETOR_KTAN(role,startDate,endDate,status,type)}`,
				"type": "GET",
				"beforeSend": function (xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem(CONFIG.TOKEN_USER));
				}
			},
			"columns": [
				{ data: 'created_at'},
				{ data: 'amount', render: function (data) {
					var amount = data;

					// Membuat objek Intl.NumberFormat untuk mata uang Rupiah (IDR)
					var formatter = new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR'
					});

					// Menggunakan formatter untuk memformat angka menjadi mata uang Rupiah
					var formattedAmount = formatter.format(amount);

					return formattedAmount
				} },
				{ data: 'bukti_bayar_deposit', render: function (data) {
					let content = '';
					if (data == null || data == '') {
						content = '<strong>Tidak ada file</strong>'
					}else {
						content = `<img src="${data}" width="80px" class="rounded">`
					}
					return content
				} },
				{ data: 'description'},
				{ data: 'status', render: function (data) {
					let content = ``
					if (data == 'Lunas') {
						content = `<div class="badge badge-success">${data}</div>`
					}else if (data == 'Menunggu Konfirmasi') {
						content = `<div class="badge badge-warning">${data}</div>`
					}else {
						
						content = `<div class="badge badge-danger">${data}</div>`
						
					}
					return content
				}},
				{ data: 'approval_by', render: function (data) {
					let content = '';
					if (data == null || data == '') {
						content = '<strong>Tidak ada data</strong>'
					}else {
						content = data
					}
					return content
				}},
				{ data: 'approval_at', render: function (data) {
					let content = '';
					if (data == null || data == '') {
						content = '<strong>Tidak ada data</strong>'
					}else {
						content = data
					}
					return content
				}},

			],
			order: [
				[0, 'desc']
			],
			"columnDefs": [
			{
				"targets": [0],
				"visible": true,
				"searchable": false,
				"type": "date"
			},
			{
				"targets": [2],
				"className": "text-center"
			},
			{
				"targets": 7,
				"data": null,
				render: function (data,type,rows) {
					let content = ``
					if (rows.status == 'Lunas') {
						content = `
						<div style="display:flex">
							<button style="" data-toggle="modal" data-target="#commentModalSetor" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-sticky-note'></i><br>Noted</button>
						</div>`
					}else {
						content = `
						<div style="display:flex">
							<button id="btnBayar" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalBayarIuran" ><i class="fas fa-dollar-sign"></i> <br>Bayar</button>&nbsp;
							<button style="" data-toggle="modal" data-target="#commentModalSetor" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-sticky-note'></i><br>Noted</button>&nbsp;
							<button id="btnCancel" class="btn btn-sm btn-danger" ><i class="fas fa-trash"></i> <br>Batal</button>
						</div>`
					}

					return content
				}
			},
			]
		});
	},


	async _requestInvoice() {
		const eventRequestInvoice = async (e) => {
			$("#btnRequestInvoice").html('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');
			let jumlahSetor = document.getElementById('numberJumlahBayar').value;
			jumlahSetor = jumlahSetor.replace(/\.+/g, '');
			let catatan = document.getElementById('catatan').value;
	
			const requestInvoice = await ApiStats.requestInvoiceKTAN({
				role:'pd',
				totalBayar: jumlahSetor,
				description: catatan
			});
	
			if (requestInvoice.status_code == 200) {
				NotificationModal.show(requestInvoice.message, 'success');
				$("#getListSetor").DataTable().ajax.reload();
				document.getElementById('numberJumlahBayar').value = '';
				document.getElementById('catatan').value = '';
			} else {
				NotificationModal.show(requestInvoice.message, 'error');
				$("#getListSetor").DataTable().ajax.reload();
				document.getElementById('numberJumlahBayar').value = '';
				document.getElementById('catatan').value = '';
			}
			$("#btnRequestInvoice").html('<i class="fas fa-file-invoice-dollar"></i> Buat Invoice');
		}
	
		const eventValidation = async () => {
			let kewajibanSetor = $('#sisa').html();
			kewajibanSetor = kewajibanSetor.replace(/[Rp.;&nbsp;]/g, "");
			let jumlahSetor = document.getElementById('numberJumlahBayar').value;
			jumlahSetor = jumlahSetor.replace(/\.+/g, "");
			
			if (!jumlahSetor) {
				NotificationModal.show("Jumlah setor belum diisi.");
			} else {
				kewajibanSetor = parseFloat(kewajibanSetor);
				jumlahSetor = parseFloat(jumlahSetor);
	
				if (kewajibanSetor <= 0) {
					NotificationModal.show("PC anda tidak memiliki kewajiban setor", 'warning');
					document.getElementById('numberJumlahBayar').value = '';
					document.getElementById('catatan').value = '';

				} else if (jumlahSetor > kewajibanSetor) {
					NotificationModal.show("Jumlah setor anda melebihi kewajiban setor", 'warning');
					document.getElementById('numberJumlahBayar').value = '';
					document.getElementById('catatan').value = '';
				} else {
					eventRequestInvoice();
				}
			}
		}
	
		document.getElementById('btnRequestInvoice').addEventListener('click', eventValidation);
	},	

	
	async _cancelInvoiceIuran(){
		$('#getListSetor tbody').on('click', '#btnCancel', async function () {
			const table = $('#getListSetor').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let invoiceID = data.id
			var date = new Date(data.created_at);
			var year = date.getFullYear();
			var month = ("0" + (date.getMonth() + 1)).slice(-2); // Ditambah 1 karena bulan dimulai dari 0
			var day = ("0" + date.getDate()).slice(-2);
			var hours = ("0" + date.getHours()).slice(-2);
			var minutes = ("0" + date.getMinutes()).slice(-2);
			var seconds = ("0" + date.getSeconds()).slice(-2);
			var timeZone = date.getTimezoneOffset();


			let dateFormated = day + '-' + month + '-' + year

			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong> Tanggal Invoice </strong>: " + dateFormated,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Batalkan!'
			}).then(async (result) => {
				if (result.value) {

					const canceInvoice = await ApiStats.cencelInvoiceKTAN({
						invoiceID: invoiceID
					});

					if (canceInvoice.status_code == '200') {
						swal.fire({
							title: 'Invoice berhasil di batalkan',
							text: canceInvoice.message,
							icon: 'success',
							showConfirmButton: true
						});
						
						$("#getListSetor").DataTable().ajax.reload();
						//refres manual 
					}else {
						swal.fire({
							title: 'Error',
							text: canceInvoice.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		$('#getListSetor tbody').on('click', '#btnBayar', async function () {
			const table = $('#getListSetor').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let invoiceID = data[8]

		});
	},
	async _initCommentIuran() {
		$('#getListSetor tbody').on('click', '#comment', async function () {

			const table = $('#getListSetor').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let idPengajuan = data.id;


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiStats.getCommentar(idPengajuan,"deposit-ktan")
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});
	},

	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	


}



export default SharingKTANPDPresenter;