import StatistikKTANPPPresenter from '../../../presenter/menu_pengurus_pp/statistik-ktan-pp-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';


const StatistikKTANPP = {
	async render() {
		const view = `
		<!-- DataTales Example -->
       
        <div class="row">
            <div class="col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
                        <i class="fas fa-chart-bar"></i> STATISTIK PENDAPATAN ANGGOTA BARU DAN KTAN TIAP PD
                    </div>
                    <div class="card-body">
                        <div class="row mb-3 d-none">
                            <div class="col-sm-12 col-xl-12 col-lg-12 float-right">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Dari Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateDariTanggal"></input>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="control-label font-weight-bold">Sampai Tanggal</label>
                                        <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateSampaiTanggal"></input>
                                    </div>
                                    <div class="col-sm-12 text-right">
                                        <button class="btn btn-primary mt-4" id="btnHitungData"><i class="fas fa-calculator"></i> Hitung Data</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableListIuranPD" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>KODE PD</th>
                                                <th>NAMA PD</th>
                                                <th>PENDAPATAN KTAN DAN ANGGOTA BARU</th>
                                                <th>KEWAJIBAN SETOR KE PP (10%)</th>
                                                <th>TELAH DISETORKAN</th>
                                                <th>SISA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line end"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between text-primary font-weight-bold">
                        <i class="fas fa-check"></i> APPROVEMENT SHARING ANGGOTA BARU DAN KTAN
                    </div>
                    <div class="card-body">
                        <div class="card mb-3">
                            <div class="card-header text-primary font-weight-bold">
                                Filter Data
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-xl-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">Status</label>
                                                <select id="selectStatusIn" class="form-control">
                                                    <option value="confirm">Menunggu Konfirmasi</option>
                                                    <option value="approve">Lunas</option>
                                                    <option value="pending">Belum Bayar</option>
                                                    <option value="">Semua</option>
                                                </select>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="control-label font-weight-bold">PD</label>
                                                <select id="selectPDIn" class="form-control">
                                                    <option value="">Pilih Salah Satu</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                            
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover" id="tableSetorPD" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>TANGGAL SETOR</th>
                                                <th>NAMA PD</th>
                                                <th>JUMLAH SETOR</th>
                                                <th>METODE PEMBAYARAN</th>
                                                <th>CATATAN</th>
                                                <th>BUKTI BAYAR SETOR</th>
                                                <th>STATUS</th>
                                                <th>AKSI</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                            <tr>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                                <td><div class="shimmer title-line"></div></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            
                                        </tfoot>
                                        <tbody>
                                        
                                        
                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>

    
    <div class="modal fade" id="commentModalReject" tabindex="-1" role="dialog" aria-labelledby="commentModal"
    aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-primary font-weight-bold">
                    Revisi & Berikan Catatan
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <form id="form-comment">
                                <label class="control-label font-weight-bold">Komentar</label>
                                <textarea class="form-control" id="komentarPengajuanReject" name="komentar" placeholder="Komentar..." required></textarea>
                                <br>
                                <button type="button" class="form-control btn btn-sm btn-primary" id="btnSendKomentarPengajuanReject"><i class='fas fa-comment'></i> Revisi & Berikan Catatan</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="commentModalSetor" tabindex="-1" role="dialog" aria-labelledby="commentModal"
    aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-primary font-weight-bold">
                    Noted Sharing
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="chat-body" id="content-comment"  style="height:400px; overflow-y:auto">
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-left">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-left text-dark text-left bubble-shimmer-left" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                                <div style="width:100%">
                                    <div style="width:10%;" class="float-right">
                                        <div class="shimmer circle"></div>
                                    </div>
                                    <div class="float-right text-dark text-left bubble-shimmer-right" style="width:88%;">
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line"></div>
                                        <div class="shimmer content-line end"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modalBayarIuran" tabindex="-1" role="dialog" aria-labelledby="modalBayarIuran" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold text-primary">Upload Bukti Bayar</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body" id="ttd">
                    <div class="alert alert-info">
                        <h5 class="font-weight-bold">Informasi Rekening :</h5>
                        <div id="informasiRekening2">

                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label font-weight-bold col-sm-12">Tanggal Setor</label>
                        <div class="col-sm-12">
                            <input type="date"  pattern="\d{2}-\d{2}-\d{4}" class="form-control" id="dateTanggalSetor">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="control-label font-weight-bold col-sm-12">Metode Pembayaran</label>
                        <div class="col-sm-12">
                            <select class="form-control" id="selectPaymentMethod">
                                <option value="">Pilih Salah Satu</option>
                                <option value="cash">Cash</option>
                                <option value="bank_transfer">Bank Transfer</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label font-weight-bold col-sm-12">Upload Bukti Bayar (max 500KB): </label>
                        <div id="form-upload-bukti-bayar-iuran" class="col-sm-12 dropzone">
                            <input type="hidden" name="file" style=""/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="btnSaveIuran">Kirim Bukti Bayar</button>
                    <button class="btn btn-secondary" id="btnCancel" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
	<div style="display:none;" id="my-template">
        <div id="mytmp" class="dz-preview dz-file-preview">
            <div class="dz-image"><img loading="lazy" data-dz-thumbnail width="200px" /></div>
            <div class="dz-details">
                <div class="dz-size"><span data-dz-size></span></div>
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
            <div class="dz-success-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="54px" viewBox="0 0 54 54" width="54px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
            </div>
            <div class="dz-error-mark">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="19" r="2" />
                    <path d="M10 3h4v12h-4z" />
                </svg>
            </div>
            <div class="dz-remove" data-dz-remove>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
            </div>
        </div>
    </div>

		

        
        `;
		return view;
	  },

	async afterRender() {
		await StatistikKTANPPPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default StatistikKTANPP;