
import API_ENDPOINT_ANGGOTA from "../../config/globals/menu_anggota_endpoint.js";
import CONFIG from "../../config/globals/config.js";
import ApiAnggota from "../../api/data-anggota.js";
import UrlParser from "../../routes/url-parser.js";
import NotificationModal from "../../utils/initial_notification.js";
import ApiNotication from "../../api/data-notification.js";

const IuranAnggotaPresenter = {

	async init() {
		await this._setView();
		await this._getInvoice();
		await this._readNotification()
		await this._sendIuran();
		await this.cancelInvoiceIuran();
		await this._initCommentIuran()
	},

	async _readNotification(){
		if (UrlParser._getParameterByName("readNotificationID") != null) {
			await ApiNotication.readNotification(UrlParser._getParameterByName("readNotificationID"))
		}
	},
	
	async formatAngka(angka) {
		// Mengubah angka menjadi bilangan desimal dengan 2 angka di belakang koma
		angka = parseFloat(angka).toFixed(2);
		
		// Menambahkan titik sebagai pemisah ribuan
		angka = angka.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		
		return angka;
	},
	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		$("#btnUpdateSKP").hide();
	},

	
	async _showLoading(){
		
		
	},
	
	async _hideLoading(){
		
	},
	
	async _getInvoice(){
		this._showLoading();
		let getInvoice = await ApiAnggota.getInvoice();
		let dataInvoice = getInvoice.data;
		let membershipFee = dataInvoice.membership_fee;
		let lastMembershipFee = dataInvoice.last_membership_fee;
		let nextMembershipFee = dataInvoice.next_membership_fee;
		let nextMembershipFeeNum = dataInvoice.next_membership_fee_num;
		let rekeningPC = dataInvoice.rekening_pc;
		let today = new Date();
		let thisMonth = today.getFullYear()+'-'+String(today.getMonth() + 1).padStart(2, '0');
		let totalIuran = 0;
		let totalIuranText = '';
		let selisihBulan = 0;
		if (getInvoice.status_code == 200) {
			if (dataInvoice.is_membership_paid == false) {
				$("#statusIuran").addClass('text-danger')
				$("#statusIuran").html('Belum Bayar')
				if (nextMembershipFeeNum == null) {
					$("#jatuhTempo").html(nextMembershipFee+" Segera Lakukan Pembayaran Iuran Untuk Dapat Mengakses Fitur Perizinan");
				}else {
					$("#jatuhTempo").html("01-"+nextMembershipFee);
				}
			}else {
				$("#statusIuran").html('Lunas')
				$("#statusIuran").addClass('text-success')
				$("#warning-iuran").addClass('d-none')
				$("#jatuhTempo").html("01-"+nextMembershipFee);
			}

			
			if (nextMembershipFeeNum == null) {
				$("#startBulan").attr("max", thisMonth);
				$("#endBulan").attr("min", thisMonth);
				$("#startBulan").val(thisMonth);
			}else {
				$("#startBulan").attr("max", nextMembershipFeeNum);
				$("#endBulan").attr("min", nextMembershipFeeNum);
				$("#startBulan").val(nextMembershipFeeNum);
			}

			let nominal = 0
			if (membershipFee == undefined) {
				nominal = 0;
			}else {
				nominal = await this.formatAngka(membershipFee);
			}
			$("#nominalIuran").html("Rp. "+ nominal);
			$("#tableIuranTahunan").html("Rp. "+ nominal);
			$("#informasiRekening").html(rekeningPC);
			$("#informasiRekening2").html(rekeningPC);
		}else if(getInvoice.status_code == 404){
			$("#statusIuran").html('Anda belum membayar iuran')
			$("#jatuhTempo").html(today)
			$("#statusIuran").addClass("text-danger")
		}

		let history = getInvoice.data.history;

		const contentHistory = [];

		if (history == "" || history == null) {
			// $("#historyPembayaran").DataTable({
			// 	"scrollX":true
			// });
		}else {
			history.forEach(async dataHistory => {
				let time =  dataHistory.created_at;
				let grandTotal = "Rp. "+ await this.formatAngka(dataHistory.grand_total)
				let buktiBayar = dataHistory.bukti_bayar_membership;
				let contentBuktiBayar = ''
				let contentButton = '';

				if (dataHistory.status == "Menunggu Konfirmasi Admin Cabang Kabupaten/Kota" || dataHistory.status == "Lunas") {
					contentButton = 'd-none'
				}else {
					contentButton = '';
				}
				if (buktiBayar == "") {
					contentBuktiBayar = 'Belum ada bukti bayar';
				}else {
					contentBuktiBayar = `<img loading="lazy" src="${dataHistory.bukti_bayar_membership}" alt="Bukti Bayar" width="40px">`
				}
				const data = [
					dataHistory.invoice_id,
					time,
					dataHistory.fee_name,
					grandTotal,
					dataHistory.jatuh_tempo,
					dataHistory.status,
					contentBuktiBayar,
					dataHistory.province_name,
					dataHistory.cabang_name,
					`
					<div class="d-flex">
					<button id="btnBayar" class="btn btn-sm btn-primary ${contentButton}" data-toggle="modal" data-target="#modalBayarIuran" ><i class="fas fa-dollar-sign"></i> Bayar</button>&nbsp;
					<button style="position:relative;" data-toggle="modal" data-target="#commentModalIuran" class='btn btn-info btn-sm' id='comment' title='Komentar'><i class='fas fa-comment fa-fw'></i> Komentar <span class="badge-chat" style="">${dataHistory.comment_unread_count}</span></button>&nbsp;
					<button id="btnCancel" class="btn btn-sm btn-danger ${contentButton}" ><i class="fas fa-trash"></i> Batal</button>
					</div>
					`,
					dataHistory.id,
				]
				contentHistory.push(data)

				
			});

		

		}
		$("#jmlIuran").html("Rp." + await this.formatAngka(membershipFee));
		const processInvoice = async (e) => {

			let akhirBulan = $('#endBulan').val();
			let awalBulan = $("#startBulan").val();

			var bulanPertama = new Date(awalBulan);
			var bulanKedua = new Date(akhirBulan);

			selisihBulan = (bulanKedua.getFullYear() - bulanPertama.getFullYear()) * 12;
			selisihBulan -= bulanPertama.getMonth() + 1;
			selisihBulan += bulanKedua.getMonth() + 1;
			selisihBulan = selisihBulan+1
			$("#jmlBulan").html(selisihBulan);
			totalIuran = parseInt(membershipFee)*parseInt(selisihBulan);
			totalIuranText = await this.formatAngka(totalIuran);
			$("#totalIuran").html("Rp. "+ await this.formatAngka(totalIuran));
		}

		const requestInvoice = async (e) => {
			let getDay = String(today.getDate() + 1).padStart(2, '0')
			let akhirBulan = $('#endBulan').val();
			let awalBulan = $("#startBulan").val();
			if (awalBulan.slice(-2) != "02") {
				if (getDay > 30) {
					getDay = '30'
				}else {
					getDay = getDay
				}
			}else {
				if (getDay > 28) {
					getDay = '28'
				}else {
					getDay = getDay
				}
			}

			if (akhirBulan.slice(-2) != "02") {
				getDay = getDay
			}else {
				getDay = '28'
			}
			let akhirBulanDay = $('#endBulan').val();
			let awalBulanDay = $("#startBulan").val();
			if (akhirBulan == '' || akhirBulan == null) {
				NotificationModal.show('Sampai bulan tidak boleh kosong !', 'error')
			}else {
				$("#btnRequestInvoice").html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
				const requestInvoiceApi = await ApiAnggota.requestInvoice({
					bulanAwal : awalBulanDay,
					bulanAkhir : akhirBulanDay,
					nominalIuran : membershipFee,
					jumlahBulan : selisihBulan,
					totalIuran : totalIuran
				})

				if (requestInvoiceApi.status_code == 200) {
					$("#btnRequestInvoice").html(`<i class="fas fa-file-invoice-dollar"></i> Buat Invoice`);
					swal.fire({
						title: 'Success',
						html: "Reload halaman untuk dapat melakukan pembayaran iuran",
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ya, Reload Halaman'
					}).then(async (result) => {
						if (result.value) {
							window.location.reload();
						};
					});
				}else{
					$("#btnRequestInvoice").html(`<i class="fas fa-file-invoice-dollar"></i> Buat Invoice`);
					NotificationModal.show(requestInvoiceApi.message, 'error')
				}


			}
		}
		
		$("#historyPembayaran").DataTable({
			"data": contentHistory,
			"scrollX":true,
			"order": [[0, 'desc']],
		});
		document.getElementById('endBulan').addEventListener('change', processInvoice);
		document.getElementById('startBulan').addEventListener('change', processInvoice);
		document.getElementById('btnRequestInvoice').addEventListener('click', requestInvoice);

		this._hideLoading();

		
	},
	
	async _initCommentIuran() {
		let idPengajuan = '';
		let userID = $("#textUserID").val();
		$('#historyPembayaran tbody').on('click', '#comment', async function () {

			const table = $('#historyPembayaran').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let userID = $("#textUserID").val();
			idPengajuan = data[10];


			let getContentAdmin = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
					<div style="width:100%">
						<div style="width:10%;" class="float-left">
							<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
						</div>
						<div class="float-left text-dark text-left" style="width:88%;">
							<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
								<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
								<small>${user.body}</small><br>
								<div class="text-right">
									<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
								</div>
							</div>
						</div>
					</div>
					`
			}

			let getContentUser = (user) => {
				let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
				return `
						<div style="width:100%">
							<div style="width:10%;" class="float-right">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-right text-dark text-left" style="width:88%;">
								<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
			}


			let response = await ApiAnggota.getCommentar(idPengajuan,"membership_fee")
			let dataComment = response.data;
			dataComment.reverse();

			if (dataComment.length <= 0) {
				$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
			} else {
				let getAllContent = async () => {
					let elmHtml = ``
					await dataComment.forEach(async element => {
						elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
					});
					return elmHtml
				}


				if (response.data == undefined || response.data.length <= 0) {
					document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
				} else {
					document.getElementById("content-comment").innerHTML = await getAllContent();

					setTimeout(() => {
						var elem = document.getElementById('content-comment');
						elem.scrollTop = elem.scrollHeight;
					}, 400);
				}
			}

		});

		const eventSendCommentar = async (e) => {
			e.preventDefault();
			let userID = $("#textUserID").val();
			document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let content = document.getElementById("komentarPengajuanSKP").value
			const response = await ApiAnggota.sendCommentar({
				pengajuanID: idPengajuan,
				contentComment: content,
				commentFor: 'membership_fee',
			});
			if (response.status_code == 200 || 201) {


				let getContentAdmin = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
						<div style="width:100%">
							<div style="width:10%;" class="float-left">
								<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
							</div>
							<div class="float-left text-dark text-left" style="width:88%;">
								<div class="float-left" style="background:#dedede; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:0px 10px" id="commentUser">
									<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
									<small>${user.body}</small><br>
									<div class="text-right">
										<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
									</div>
								</div>
							</div>
						</div>
						`
				}

				let getContentUser = (user) => {
					let time = new Date(user.updated_at).toLocaleDateString('en-US') + " " + new Date(user.updated_at).toLocaleTimeString('en-US');
					return `
							<div style="width:100%">
								<div style="width:10%;" class="float-right">
									<img loading="lazy" class="rounded-circle img-thumbnail img-square float-left" id="imgUser" src="${user.actor.avatar}" style="width:50px; height:50px;">
								</div>
								<div class="float-right text-dark text-left" style="width:88%;">
									<div class="float-right" style="background:#e0e8fc; margin:5px; padding:0 12px; padding-bottom:5px; border-radius:10px 0px" id="commentUser">
										<small><b>${user.actor.name} [${user.actor.role}]</b></small><hr>
										<small>${user.body}</small><br>
										<div class="text-right">
											<small style="margin-top:-2px; margin-left:50px"><strong>${time}</strong></small>
										</div>
									</div>
								</div>
							</div>
							`
				}


				let response = await ApiAnggota.getCommentar(idPengajuan,"membership_fee")
				let dataComment = response.data;
				dataComment.reverse();

				if (dataComment.length <= 0) {
					$("#content-comment").html("<br><center><i>Tidak ada komentar</i></center>");
				} else {
					let getAllContent = async () => {
						let elmHtml = ``
						await dataComment.forEach(async element => {
							elmHtml = elmHtml + (element.actor_type == "admin" ? getContentAdmin(element) : getContentUser(element))
						});
						return elmHtml
					}


					if (response.data == undefined || response.data.length <= 0) {
						document.getElementById("content-comment").innerHTML = `<span class="no-comment">Belum ada komentar</span>`
					} else {
						document.getElementById("content-comment").innerHTML = await getAllContent();

						setTimeout(() => {
							var elem = document.getElementById('content-comment');
							elem.scrollTop = elem.scrollHeight;
						}, 400);
					}
				}

				$('#komentarPengajuanSKP').val('');
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			} else {
				document.getElementById("btnSendKomentarPengajuanSKP").innerHTML = `<i class="fas fa-comment"></i> Berikan Komentar`;
			}
			e.preventDefault();
		}
		document.getElementById("btnSendKomentarPengajuanSKP").addEventListener("click", eventSendCommentar)
	},
	async _sendIuran(){
		let DzoneIuran = new Dropzone("#form-upload-bukti-bayar-iuran", { 
			url: "/file/post", 
			uploadMultiple: false,
			autoDiscover:false, 
			maxFiles: 1,
			maxFilesize: 1,
			autoQueue: false,
			acceptedFiles: ".jpeg,.jpg,.png,.gif",
			dictDefaultMessage: 'Drop image here (or click) to capture/upload',
			dictCancelUploadConfirmation:  "Apa kamu yakin ingin menghapus foto ini?",
			previewTemplate: document.querySelector("#my-template").innerHTML,
			init: function() {
				this.on("maxfilesexceeded", function(file) {
					this.removeAllFiles();
					this.addFile(file);
				});
				this.on("error", function(file, message) { 
					this.removeFile(file); 
				});
				this.on("addedfiles", function(files) {
					this.emit("removeThumbnailCustom")
					this.removeAllFiles();
					this.addFile(files[0]);
			   });
				this.on("removeThumbnailCustom", function() { 
					$('#form-upload-bukti-bayar-iuran .dz-preview:hidden').remove()
					$('#form-upload-bukti-bayar-iuran .dz-preview').fadeOut('slow');
					document.getElementById("form-upload-bukti-bayar-iuran").classList.remove('dz-started');
				});
			}
		});



		const eventCreatPembayaran = async (e) => {
			e.preventDefault();
			if (DzoneIuran.files.length <= 0) {
				NotificationModal.show('Bukti bayar tidak boleh kosong !', 'error')
				document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;
			}else {
				document.getElementById("btnSaveIuran").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
				const createPembayaranIuran = await ApiAnggota.sendBuktiBayar({
				invoiceID : document.getElementById("textInvoiceId").value,
				buktiBayar : (DzoneIuran.files.length <= 0) ? null : await this.dataUrlToFile(DzoneIuran.files[0].dataURL ,DzoneIuran.files[0].name )
				});
				if (createPembayaranIuran.status_code != 200) {
					NotificationModal.show(`${createPembayaranIuran.message}`, 'error');
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;				
				} else {
					swal.fire({
						title: 'Success',
						html: "Bukti bayar iuran berhasil di kirim",
						icon: 'success',
						showCancelButton: false,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: '<i class="fas fa-check"></i> Ok'
					}).then(async (result) => {
						if (result.value) {
							window.location.reload();
						};
					});
					DzoneIuran.emit("removeThumbnailCustom");
					document.getElementById("btnSaveIuran").innerHTML = `Kirim Bukti Bayar`;
				}
				

				e.preventDefault();

			}
		}
		document.getElementById('btnSaveIuran').addEventListener('click', eventCreatPembayaran);

		
		
		
	},

	async cancelInvoiceIuran(){
		$('#historyPembayaran tbody').on('click', '#btnCancel', async function () {
			const table = $('#historyPembayaran').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let invoiceID = data[0]

			
			swal.fire({
				title: 'Hapus',
				html: "Anda ingin menghapus data ini? <br> <strong> INVOICE ID </strong>: " + invoiceID,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, Batalkan!'
			}).then(async (result) => {
				if (result.value) {

					const canceInvoice = await ApiAnggota.cencelInvoice({
						invoiceID: invoiceID
					});

					if (canceInvoice.status_code == '200') {
						swal.fire({
							title: 'Batalkan Invoice',
							text: canceInvoice.message,
							icon: 'success',
							showConfirmButton: true
						});
						//refres manual 
						window.location.reload();
					}else {
						swal.fire({
							title: 'Error',
							text: canceInvoice.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 1000
						});
					}
				};
			});


		});

		$('#historyPembayaran tbody').on('click', '#btnBayar', async function () {
			const table = $('#historyPembayaran').DataTable();
			let data = table.row($(this).parents('tr')).data();
			let invoiceID = data[10]
			document.getElementById("textInvoiceId").value = invoiceID

		});
	},
	
	async dataUrlToFile(dataUrl, fileName) {
		const res = await fetch(dataUrl);
		const blob = await res.blob();
		return new File([blob], fileName, { type: 'image/png' });
	},

	


}

export default IuranAnggotaPresenter;