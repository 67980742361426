import ApiRole from "../../api/api_pengurus_pp/data-role.js";
import NotificationModal from "../../utils/initial_notification.js";

const DownloadSuratMandatPCPresenter = {

	async init() {
		await this._setView();
		await this.renderGetUserByKtan()
		await this._generateSurat();
	},

	async _setView() {
		document.getElementById('main-content').classList.remove('bg-gradient-primary');
		document.getElementById('main-content').style.removeProperty("height");
		document.getElementById('main-content').style.removeProperty("padding-top");
		document.getElementById('content-wrapper').style.removeProperty("overflow-x");
		document.getElementById('appbar').classList.remove('d-none');
		document.getElementById('accordionSidebar').classList.remove('d-none');
		document.getElementById("main-content").style.height = "";
		
		const formattedNumberInput = document.getElementById('textNian');
		const blocks = [4, 8, 1, 6]; // Jumlah digit pada setiap blok
		const totalLength = blocks.reduce((total, num) => total + num, 0);
	  
		formattedNumberInput.addEventListener('input', (event) => {
		  const inputValue = event.target.value;
		  const validInput = inputValue.replace(/\D/g, ''); // Hapus karakter non-digit
	  
		  // Potong input jika panjang melebihi total panjang blok
		  const formattedValue = validInput.slice(0, totalLength);
	  
		  let formattedResult = '';
		  let index = 0;
	  
		  for (const block of blocks) {
			const chunk = formattedValue.slice(index, index + block);
			formattedResult += chunk;
	  
			if (formattedValue.length > index + block) {
			  formattedResult += '.';
			}
	  
			index += block;
		  }
	  
		  event.target.value = formattedResult;
	  
		  // Nonaktifkan input jika format sudah mencapai panjang yang diinginkan
		  if (formattedValue.length >= totalLength) {
			event.preventDefault();
		  }
		});
	},

	async _generateSurat(){
		const eventGenerateSurat = async (e) => {
			e.preventDefault()
			document.getElementById("btnGenerate").innerHTML = `<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`
			let selectJenisSurat = document.getElementById('selectJenisSurat').value
			let textNomorSurat = document.getElementById('textNomorSurat').value
			let tanggalSurat = document.getElementById('dateTanggal').value
			let textNian = document.getElementById('textNian').value

			if (selectJenisSurat == "" || textNomorSurat == "" || tanggalSurat == "" || textNian == "") {
				NotificationModal.show('Harap lengkapi semua formulir isian !' ,'warning')
			}else {
				let response = await ApiRole.downloadSuratMandat({
					jenisSurat : selectJenisSurat,
					nomorSurat : textNomorSurat,
					tanggalSurat : tanggalSurat,
					nian : textNian,
					adminType : "admin_pc"
				})

				NotificationModal.show('Generate Surat Berhasil !', 'success')

			}

			

			e.preventDefault()
			document.getElementById("btnGenerate").innerHTML = `<i class="fas fa-download"></i> Generate`

			
		}

		document.getElementById('btnGenerate').addEventListener('click', eventGenerateSurat)
	},

	
    async renderGetUserByKtan() {
		const eventGetUser = async () => {
			let ktanValue = document.getElementById("textNian").value;
			let response = await ApiRole.getUserByKTAN(ktanValue);
			if (ktanValue == '') {
				$("#textName").attr('placeholder','Nama Lengkap')
				$("#textEmail").attr('placeholder','Email')
				document.getElementById("userId").value = ""
				$("#textName").removeClass('is-invalid')
				$("#textEmail").removeClass('is-invalid')
				$("#textName").removeClass('is-valid')
				$("#textEmail").removeClass('is-valid')
				document.getElementById("textName").value = "";
				document.getElementById("textEmail").value = "";
			}else {
				$("#textName").attr('placeholder','Loading...')
				$("#textEmail").attr('placeholder','Loading...')
				if (response.status_code == 200) {
					document.getElementById("textName").value = response.data.name;
					document.getElementById("textEmail").value = response.data.email;
					$("#textName").removeClass('is-invalid')
					$("#textEmail").removeClass('is-invalid')
					$("#textName").addClass('is-valid')
					$("#textEmail").addClass('is-valid')
				}else{
					$("#textName").attr('placeholder','User Tidak Ditemukan')
					$("#textEmail").attr('placeholder','User Tidak Ditemukan')
					$("#textName").removeClass('is-valid')
					$("#textEmail").removeClass('is-valid')
					$("#textName").addClass('is-invalid')
					$("#textEmail").addClass('is-invalid')
					document.getElementById("textName").value = "";
					document.getElementById("textEmail").value = "";
				}
			}
		}
		document.getElementById("textNian").addEventListener("keyup",eventGetUser)
	},



}



export default DownloadSuratMandatPCPresenter;