import CONFIG from './config.js';

const API_ENDPOINT = {
	IP_SERVER: `${CONFIG.BASE_URL}login/ipServer`,
	LOGIN: (role) => `${CONFIG.BASE_URL}login/${role}`,
	LOGOUT : `${CONFIG.BASE_URL}logout`,

	REGENCY: (provinceID) => `${CONFIG.BASE_URL}data/regency/${provinceID}`,
	REGENCY_KEMENDAGRI: (provinceID) => `${CONFIG.BASE_URL}data/address/city/${provinceID}`,
	SUBREGENCY: (regencyID) => `${CONFIG.BASE_URL}data/subregency/${regencyID}`,
	SUBREGENCY_KEMENDAGRI: (regencyID) => `${CONFIG.BASE_URL}data/address/district/${regencyID}`,
	VILLAGE: (subRegencyID) => `${CONFIG.BASE_URL}data/village/${subRegencyID}`,
	VILLAGE_KEMENDAGRI: (subRegencyID) => `${CONFIG.BASE_URL}data/address/village/${subRegencyID}`,
	PROVINCE : `${CONFIG.BASE_URL}data/province`,
	PROVINCE_KEMENDAGRI : `${CONFIG.BASE_URL}data/address/province`,
	CREATE_USER : `${CONFIG.BASE_URL}register`,
	UPDATE_PASSWORD : `${CONFIG.BASE_URL}me/password`,
	CREATE_USER_OLD : `${CONFIG.BASE_URL}register/old`,
	RESET_PASSWORD : `${CONFIG.BASE_URL}password/reset`,
	RESET_PASSWORD_BY_EMAIL : `${CONFIG.BASE_URL}password/reset`,
	GET_DATA_ANGGOTA : `${CONFIG.BASE_URL}me`,
	GET_TRACKING_KTAN : `${CONFIG.BASE_URL}tracking/user/ktan`,
	GET_INVOICE : `${CONFIG.BASE_URL}invoice/membership/user/detail`,
	REQUEST_INVOICE : `${CONFIG.BASE_URL}invoice/membership/user/request`,
	CANCEL_INVOICE : (invoiceID) => `${CONFIG.BASE_URL}invoice/membership/user/cancel/${invoiceID}`,
	SEND_IURAN : `${CONFIG.BASE_URL}invoice/membership/user/paid`,
	UPDATE_DATA_ANGGOTA : `${CONFIG.BASE_URL}me/profile`,
	UPDATE_DATA_DOMICILE : `${CONFIG.BASE_URL}me/domicile`,
	CREATE_PENDIDIKAN : `${CONFIG.BASE_URL}me/pendidikan`,
	GET_PENDIDIKAN_BY_ID : (pendidikanID)=>`${CONFIG.BASE_URL}me/pendidikan/${pendidikanID}`,
	CREATE_PEKERJAAN : `${CONFIG.BASE_URL}me/pekerjaan`,
	GET_PEKERJAAN_BY_ID : (pekerjaanID)=>`${CONFIG.BASE_URL}me/pekerjaan/${pekerjaanID}`,
	CREATE_ORGANISASI : `${CONFIG.BASE_URL}me/organisasi`,
	CREATE_STRTTK : `${CONFIG.BASE_URL}me/strttk`,
	CREATE_SIPTTK : `${CONFIG.BASE_URL}me/sipttk`,
	CREATE_SERKOM : `${CONFIG.BASE_URL}me/serkom`,
	SEND_PENGAJUAN_KTAN : `${CONFIG.BASE_URL}pengajuan/user`,
	UPDATE_PENDIDIKAN : (pendidikanID) => `${CONFIG.BASE_URL}me/pendidikan/${pendidikanID}`,
	UPDATE_PEKERJAAN : (pekerjaanID) => `${CONFIG.BASE_URL}me/pekerjaan/${pekerjaanID}`,
	UPDATE_ORGANISASI : (organisasiID) => `${CONFIG.BASE_URL}me/organisasi/${organisasiID}`,
	UPDATE_STRTTK : (strttkID) => `${CONFIG.BASE_URL}me/strttk/${strttkID}`,
	UPDATE_SERKOM : (serkomID) => `${CONFIG.BASE_URL}me/serkom/${serkomID}`,
	UPDATE_SIPTTK : (sipttkID) => `${CONFIG.BASE_URL}me/sipttk/${sipttkID}`,
	UPLOAD_MEDIA_PROFILE : `${CONFIG.BASE_URL}me/profile/media`,
	GET_COMMENTAR : (pengajuanID,commentFor,lastPage) => `${CONFIG.BASE_URL}comment/user/${commentFor}/${pengajuanID}?limit=20`,
	CREATE_COMMENTAR : (pengajuanID) => `${CONFIG.BASE_URL}comment/user/${pengajuanID}`,
	CREATE_COMMENTAR_KTAN : (commentFor) => `${CONFIG.BASE_URL}comment/user/${commentFor}`,
	GET_COMMENTAR_KTAN : (lastPage) => `${CONFIG.BASE_URL}comment/user/ktan/?limit=10&page=${lastPage}`,

	
	DOWNLOAD_STRTTK_PD : (id) => `${CONFIG.BASE_URL}print/rekom/strttk/${id}`,
	DOWNLOAD_SERKOM : (id) => `${CONFIG.BASE_URL}print/serkom/${id}`,
	DOWNLOAD_STRTTK_PC : (id) => `${CONFIG.BASE_URL}print/strttk/pc/${id}`,
	DOWNLOAD_SK_SIPTTK_PC : (id) => `${CONFIG.BASE_URL}print/sipttk/pc/${id}`,
	DOWNLOAD_SK_SIPTTK_PC_PENCABUTAN : (id) => `${CONFIG.BASE_URL}print/sipttk/pencabutan/${id}`,
	DOWNLOAD_KTAN : `${CONFIG.BASE_URL}print/ktan`,
	DOWNLOAD_BORANG_SKP : (id) =>`${CONFIG.BASE_URL}print/borang/skp/${id}`,

	DOWNLOAD_LOLOS_BUTUH_PC : (id) => `${CONFIG.BASE_URL}print/mutasi/pc/${id}`,
	DOWNLOAD_LOLOS_BUTUH_PD : (id) => `${CONFIG.BASE_URL}print/mutasi/pd/${id}`,

	
	DOWNLOAD_FORMAT_SYARAT : `${CONFIG.BASE_URL}print/strttk/syarat`,

	CHECK_MEMBERSHIP : `${CONFIG.BASE_URL}invoice/membership/user/check`,

	RESEND_VERIFICATION : `${CONFIG.BASE_URL}email/resend`,
	RESEND_PAYMENT : `${CONFIG.BASE_URL}invoice/registration/resend`,
	SEND_BUKTI_BAYAR_REGISTRASI : `${CONFIG.BASE_URL}register/user/paid`,


	LOGIN_OUAUTH: `${CONFIG.BASE_URL}oauth/token`,


	GET_DATA_ANGGOTA_CHECK : `${CONFIG.BASE_URL}me/check`,


	DOWNLOAD_SURAT_MANDAT : `${CONFIG.BASE_URL}print/surat-mandat-admin`,

};

export default API_ENDPOINT;
