import DownloadSuratMandatPCPresenter from '../../../presenter/menu_pengurus_pc/download-surat-mandat-pc-presenter.js';
import ImagePopUPInitiator from '../../../presenter/pop-up-presenter.js';





const DownloadSuratMandatPC = {
	async render() {
		const view = `
		<!-- DataTales Example -->
        <div class="row">
            <div class="col-sm-6 mx-auto">
                <div class="card mt-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between font-weight-bold text-primary">
                        Formulir Generate Surat Mandat
                    </div>
                    <div class="card-body ">
                        <form id="form-pengurus" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Jenis Surat</label>
                                        <div class="col-sm-12">
                                            <select class="form-control" id="selectJenisSurat" required>
                                                <option value="">Pilih Salah Satu</option>
                                                <option value="pengangkatan">Surat Mandat Pengangkatan</option>
                                                <option value="pemberhentian">Surat Mandat Pemberhentian</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Nomor Surat</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textNomorSurat" required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Tanggal Surat</label>
                                        <div class="col-sm-12">
                                            <input type="date" class="form-control" id="dateTanggal" required>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">NIAN</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textNian" required placeholder="XXXX.XXXXXXXX.X.XXXX">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Nama Anggota</label>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" id="textName" required readonly >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="control-label col-sm-12 font-weight-bold">Email</label>
                                        <div class="col-sm-12">
                                            <input type="email" class="form-control" id="textEmail" required readonly >
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-12 text-end">
                                            <button class="btn btn-primary" id="btnGenerate"><i class="fas fa-download"></i> Generate</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

           
        
        `;
		return view;
	  },

	async afterRender() {
		await DownloadSuratMandatPCPresenter.init();
        await  ImagePopUPInitiator.init();
	},

	_errorContent(container) {
		const errorContent = document.getElementById('main-content');
		errorContent.innerHTML = `<div class="msg-failed">
                             <span class="fa fa-pencil"></span>
                              <p class="center">Maaf , Aplikasi tidak dapat di tampilkan. Periksa kembali internet anda ya &#128522</p>
                             </div>`;
	},

};

export default DownloadSuratMandatPC;